import { IAPIError } from '@/models'

export function getFieldErrors(apiErrors: IAPIError) {
  return apiErrors.errors.reduce((acc, error) => {
    if (!error.meta) return acc

    return {
      ...acc,
      ...error.meta.fields.reduce((acc, field) => ({ ...acc, [field]: error.detail }), {}),
    }
  }, {})
}

export function getErrorMessages(
  error?: object | string,
  errorFormatter?: (error: { status: number; code: string; detail: string }) => string,
) {
  const defaultMessage = [
    "Unfortunately we couldn't provide any additional details about the error.",
  ]

  if (!error) return defaultMessage

  if (typeof error === 'string') return [error]

  if ('message' in error && typeof error.message === 'string') return [error.message]

  if ('errors' in error && Array.isArray(error.errors)) {
    return error.errors.map((error) => (errorFormatter ? errorFormatter(error) : error.detail))
  }

  return defaultMessage
}
