import { useEffect } from 'react'
import { Button, CircularProgress, Grid, Tooltip } from '@mui/material'
import { Divider } from '@/components/Divider'
import { Panel } from '@/components/Panel'
import { ErrorMessage } from '@/components/ErrorMessage'
import { PaymentOptionCard } from '@/components/PaymentOptionCard'
import { useAdminPaymentOptions } from '@/hooks/useAdminPaymentOptions'
import { routes } from '@/helpers/routes'
import { useNavigate } from 'react-router-dom'
import { useAvailablePaymentProvidersForCreation } from '@/hooks/useAvailablePaymentProvidersForCreation'

export const AdminPaymentOptions = () => {
  return (
    <Panel
      title={'Payment Options'}
      description={'In this section you will be able to configure payment options'}
      actions={<PaymentPageActions />}
      content={
        <>
          <Divider sx={{ marginBottom: '24px' }} />
          <PaymentPageContent />
        </>
      }
    />
  )
}

const PaymentPageActions = () => {
  const navigate = useNavigate()
  const { canCreate } = useAvailablePaymentProvidersForCreation()
  const toolTipText = !canCreate
    ? 'There are no merchant processors available for payment option creation.'
    : ''

  return (
    <Tooltip title={toolTipText} followCursor>
      <div>
        <Button
          onClick={() => navigate(routes.adminPaymentOptionCreation())}
          sx={{ alignSelf: 'flex-end' }}
          variant={'contained'}
          disabled={!canCreate}
          size={'small'}
          type={'button'}
        >
          Create
        </Button>
      </div>
    </Tooltip>
  )
}

const PaymentPageContent = () => {
  const { state, actions } = useAdminPaymentOptions()

  useEffect(() => {
    void actions.fetchAll()
  }, [])

  if (!state.loading && state.data?.length) {
    return (
      <Grid container spacing={2} justifyContent={{ xs: 'center', sm: 'flex-start' }}>
        {state.data.map((paymentOption) => (
          <Grid key={paymentOption.provider} item>
            <PaymentOptionCard paymentOption={paymentOption} />
          </Grid>
        ))}
      </Grid>
    )
  }

  if (state.error) return <ErrorMessage error={state.error} />

  return <CircularProgress />
}
