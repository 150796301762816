import { useRecoilState } from 'recoil'
import { useAppConfig } from '@/hooks/useAppConfig'
import { paymentServiceState } from '@/state/paymentServiceDetail'
import { EventConstants } from '@/constants'
import { TipType, TPaymentService } from '@/models'
import { useToast } from '@/hooks/useToast'

export function usePaymentServiceTips(provider: string) {
  const toast = useToast()
  const { config } = useAppConfig()
  const PaymentServiceConfiguration = config.factories.paymentServiceConfigurations
  const EventBus = config.factories.eventBus

  const [pServiceState, setPaymentServiceState] = useRecoilState(paymentServiceState)
  const { data, loading, error, updatingSetting } = pServiceState

  const handleToggleChange = async (field: string) => {
    setPaymentServiceState({ ...pServiceState, error: null, updatingSetting: true })

    try {
      const result = await PaymentServiceConfiguration().updateSettings({
        provider,
        data: {
          [field]: !pServiceState.data?.[field],
        },
      })

      EventBus().publish(EventConstants.PAYMENT_SERVICE_UPDATED, {
        paymentService: result,
      })

      setPaymentServiceState({
        data: { ...pServiceState.data, ...result } as TPaymentService,
        error: null,
        loading: false,
        updatingSetting: false,
      })
    } catch (error) {
      setPaymentServiceState({ ...pServiceState, error, loading: false, updatingSetting: false })
      return error
    }
  }

  const allowTerminalTipsToggleChange = () =>
    handleToggleChange('allowTerminalTips')
      .then(() => {
        toast.showMessage('Allow terminal tips updated.', 'info')
      })
      .catch((error) => {
        toast.showMessage(`Failed to update allow terminal tips. Details: ${error}`, 'error')
      })

  const allowCustomTipsToggleChange = () =>
    handleToggleChange('allowCustomTips')
      .then(() => {
        toast.showMessage('Allow custom tips updated.', 'info')
      })
      .catch((error) => {
        toast.showMessage(`Failed to update allow custom tips. Details: ${error}`, 'error')
      })

  const updatePredefinedTipType = async (paymentService: TPaymentService, type: TipType) => {
    setPaymentServiceState({ ...pServiceState, error: null, updatingSetting: true })

    try {
      const result = await PaymentServiceConfiguration().update({
        provider: paymentService.provider,
        tipOptions: {
          type,
          amounts: [],
        },
      })

      EventBus().publish(EventConstants.PAYMENT_SERVICE_UPDATED, {
        paymentService: result,
      })

      setPaymentServiceState({
        data: { ...pServiceState.data, ...result } as TPaymentService,
        error: null,
        loading: false,
        updatingSetting: false,
      })
      toast.showMessage('Tip type updated.', 'info')
    } catch (error) {
      setPaymentServiceState({ ...pServiceState, error, loading: false, updatingSetting: false })
      toast.showMessage(`Failed to change tip type. Details: ${error}`, 'error')
    }
  }

  const addPredefinedTip = async (paymentService: TPaymentService, predefinedTip: number) => {
    setPaymentServiceState({ ...pServiceState, error: null, updatingSetting: true })

    try {
      const predefinedTips = paymentService.tipOptions?.amounts || []
      const result = await PaymentServiceConfiguration().update({
        provider: paymentService.provider,
        tipOptions: {
          type: paymentService.tipOptions?.type as TipType,
          amounts: [...predefinedTips, predefinedTip],
        },
      })

      EventBus().publish(EventConstants.PAYMENT_SERVICE_UPDATED, {
        paymentService: result,
      })

      setPaymentServiceState({
        data: { ...pServiceState.data, ...result } as TPaymentService,
        error: null,
        loading: false,
        updatingSetting: false,
      })
      toast.showMessage('Predefined tip added.', 'info')
    } catch (error) {
      setPaymentServiceState({ ...pServiceState, error, loading: false, updatingSetting: false })
      toast.showMessage(`Failed to add predefined tip. Details: ${error}`, 'error')
    }
  }

  const removePredefinedTip = async (paymentService: TPaymentService, tipIndex: number) => {
    setPaymentServiceState({ ...pServiceState, error: null, updatingSetting: true })

    try {
      const amounts = [...(paymentService.tipOptions?.amounts || [])]
      amounts.splice(tipIndex, 1)
      const result = await PaymentServiceConfiguration().update({
        provider: paymentService.provider,
        tipOptions: {
          type: paymentService.tipOptions?.type as TipType,
          amounts,
        },
      })

      EventBus().publish(EventConstants.PAYMENT_SERVICE_UPDATED, {
        paymentService: result,
      })

      setPaymentServiceState({
        data: { ...pServiceState.data, ...result } as TPaymentService,
        error: null,
        loading: false,
        updatingSetting: false,
      })
      toast.showMessage('Predefined tip removed.', 'info')
      return { data: result }
    } catch (error) {
      setPaymentServiceState({ ...pServiceState, error, loading: false, updatingSetting: false })
      toast.showMessage(`Failed to remove predefined tip. Details: ${error}`, 'error')
      return error
    }
  }

  return {
    state: { data, loading, updatingSetting, error },
    actions: {
      allowTerminalTipsToggleChange,
      allowCustomTipsToggleChange,
      updatePredefinedTipType,
      addPredefinedTip,
      removePredefinedTip,
    },
  }
}
