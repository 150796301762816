import { Box as MuiBox, BoxProps, styled } from '@mui/material'

export const Box = styled(MuiBox)<BoxProps>(({ theme, style = {} }) => ({
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.border['100']}`,
  padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
  boxSizing: 'border-box',
  borderRadius: theme.decoration.border.radius.default,
  margin: '0 !important',
  ...style,
}))
