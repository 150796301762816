import { IconButton, Typography } from '@mui/material'
import { Box } from '@/components/Box'
import { makeStyles } from '@/theme'
import { Divider } from '@/components/Divider'
import CloseIcon from '@mui/icons-material/Close'
import { Modal } from '@/components/Modal'
import { ReactNode } from 'react'

interface IFormModalProps {
  children: ReactNode
  title: string
  onClose: () => void
}
export const FormModal = (props: IFormModalProps) => {
  const { classes, cx } = useStyles()

  return (
    <Modal
      open={true}
      sx={{ border: 0 }}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={cx(classes.root)}>
        <div className={cx(classes.header)}>
          <Typography variant="h2" color="text.primary">
            {props.title}
          </Typography>
          <IconButton onClick={props.onClose}>
            <CloseIcon sx={{ fontSize: '24px' }} />
          </IconButton>
        </div>
        <Divider />
        {props.children}
      </Box>
    </Modal>
  )
}

const useStyles = makeStyles()(() => ({
  root: {
    padding: '0',
    position: 'absolute' as const,
    border: 0,
    outline: 0,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 460,
  },
  header: {
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    padding: '24px',
    justifyContent: 'space-between',
  },
}))
