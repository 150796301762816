import { atom } from 'recoil'
import { TAnyError, TPaymentService } from '@/models'

export interface PaymentServiceState {
  loading: boolean
  error?: TAnyError | null
  data: TPaymentService | null
  updatingSetting: boolean
}

export const paymentServiceState = atom<PaymentServiceState>({
  key: 'PaymentServiceDetail',
  default: {
    loading: false,
    error: null,
    data: null,
    updatingSetting: false,
  },
})
