import { useState } from 'react'
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import { makeStyles } from '@/theme'
import {
  IFormOptionEntry,
  maxPredefinedTips,
  maxTipPercentage,
  TAnyError,
  TipType,
  TPaymentService,
} from '@/models'
import { usePaymentServiceTips } from '@/hooks/usePaymentServiceTips'
import { ErrorMessage } from '@/components/ErrorMessage'
import { Switch } from '@/components/Switch'
import { DeleteConfirmationButton } from '@/components/DeleteConfirmationButton'
import { Select } from '@/components/Select'
import { PercentInput } from '@/components/PercentInput'
import { CurrencyInput } from '@/components/CurrencyInput'
import { toDollars } from '@/helpers/currency'
import { WithConfirmationDialog } from '@/components/WithConfirmationDialog'

interface ITipsSectionProps {
  paymentService: TPaymentService
}

interface ITipTypeProps extends ITipsSectionProps {
  disabled: boolean
  options: IFormOptionEntry[]
  value: TipType | null
  updateTipType: (paymentService: TPaymentService, predefinedTip: TipType) => void
}

interface ITipsListProps extends ITipsSectionProps {
  tipType: TipType
  predefinedTips: number[]
  disabled: boolean
  addPredefinedTip: (paymentService: TPaymentService, predefinedTip: number) => void
  removePredefinedTip: (
    paymentService: TPaymentService,
    tipIndex: number,
  ) => Promise<{ error?: TAnyError }>
}

const tipTypeOptions: IFormOptionEntry[] = [
  { label: '%', value: TipType.Percentage },
  { label: '$', value: TipType.Flat },
]

export const TipsSection = (props: ITipsSectionProps) => {
  const { classes, cx } = useStyles()

  const { paymentService } = props

  const {
    state: { data, loading, updatingSetting, error },
    actions: {
      allowTerminalTipsToggleChange,
      allowCustomTipsToggleChange,
      updatePredefinedTipType,
      addPredefinedTip,
      removePredefinedTip,
    },
  } = usePaymentServiceTips(paymentService.provider)

  const {
    allowTerminalTips,
    allowCustomTips,
    tipOptions,
    tips: tipsSettings,
  } = data as TPaymentService

  const showNoTipsWarning =
    (tipsSettings?.supportCustomTips || tipsSettings?.supportPredefinedTips) &&
    allowTerminalTips &&
    !allowCustomTips &&
    (!tipOptions?.amounts || tipOptions.amounts.length < 1)

  if (error) {
    return <ErrorMessage error={error} />
  }

  if (loading) {
    return <CircularProgress />
  }

  return (
    <div className={cx(classes.container)}>
      <div className={cx(classes.fieldsContainer)}>
        <div className={cx(classes.togglesContainer)}>
          <Tooltip
            title="Allow terminal tips override. If turned off, it will disable the custom and
                predefined tips."
            arrow
            PopperProps={{ style: { zIndex: 0 } }}
          >
            <div className={cx(classes.toggleFieldContainer)}>
              <Switch
                disabled={updatingSetting}
                onChange={allowTerminalTipsToggleChange}
                checked={allowTerminalTips}
              />
              <div className={cx(classes.text)}>
                <Typography variant="subtitle1" color="text.primary">
                  Allow Terminal Tips
                </Typography>
              </div>
            </div>
          </Tooltip>
          {tipsSettings?.supportCustomTips && (
            <Tooltip
              title="This leads the customer to a screen where they can enter a tip amount."
              arrow
              PopperProps={{ style: { zIndex: 0 } }}
            >
              <div className={cx(classes.toggleFieldContainer)}>
                <Switch
                  disabled={updatingSetting || !allowTerminalTips}
                  onChange={allowCustomTipsToggleChange}
                  checked={allowCustomTips}
                />
                <div className={cx(classes.text)}>
                  <Typography variant="subtitle1" color="text.primary">
                    Custom Tips
                  </Typography>
                </div>
              </div>
            </Tooltip>
          )}
        </div>
        {tipsSettings?.supportPredefinedTips && (
          <div className={cx(classes.predefinedTipsContainer)}>
            <div className={cx(classes.titleContainer)}>
              <Typography variant="subtitle1" color="text.primary">
                Predefined Tip Amount (
                {tipTypeOptions.find((type) => type.value === tipOptions?.type)?.label})
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                Max 3
              </Typography>
            </div>
            <TipTypeField
              paymentService={paymentService}
              disabled={updatingSetting || !allowTerminalTips}
              options={tipTypeOptions}
              value={tipOptions?.type || null}
              updateTipType={updatePredefinedTipType}
            />
            {!!tipOptions?.type && (
              <TipsList
                paymentService={paymentService}
                tipType={tipOptions.type}
                predefinedTips={tipOptions?.amounts || []}
                disabled={updatingSetting || !allowTerminalTips}
                addPredefinedTip={addPredefinedTip}
                removePredefinedTip={removePredefinedTip}
              />
            )}
          </div>
        )}
      </div>
      {showNoTipsWarning && (
        <div className={cx(classes.noTipsWarning)}>
          <div className={cx(classes.noTipsTitle)}>
            <WarningRoundedIcon color={'error'} fontSize={'large'} />
            <Typography variant="subtitle1" color="red">
              Danger Zone
            </Typography>
          </div>
          <span>
            When "Allow Terminal Tips" is enabled but no "Predefined Tip Amounts" are set, and
            "Custom Tips" is not enabled, <strong>no tip options will be displayed</strong> at the
            terminal. Please ensure that at least one tip option is configured to avoid missing out
            on potential tips.
          </span>
        </div>
      )}
    </div>
  )
}

const TipTypeField = (props: ITipTypeProps) => {
  const { classes, cx } = useStyles()
  const { paymentService, disabled, options, value, updateTipType } = props

  return (
    <Tooltip
      title="Changing tip types will clear the predefined tips."
      arrow
      PopperProps={{ style: { zIndex: 0 } }}
    >
      <div className={cx(classes.tipTypeContainer)}>
        <Typography variant="subtitle1" color="text.primary">
          Tip Type
        </Typography>
        <WithConfirmationDialog
          title="Are you sure you want to change tip types?"
          text="Changing tip types will clear the predefined tips."
          onConfirm={(type) => updateTipType(paymentService, type as TipType)}
        >
          {({ onOpenConfirmation }) => (
            <Select
              className={cx(classes.tipTypeField)}
              disabled={disabled}
              placeholder="Select tip type"
              options={options}
              value={value}
              onChange={(type) => onOpenConfirmation(type as TipType)}
            />
          )}
        </WithConfirmationDialog>
      </div>
    </Tooltip>
  )
}

const TipsList = (props: ITipsListProps) => {
  const { classes, cx } = useStyles()
  const {
    paymentService,
    tipType,
    predefinedTips,
    disabled,
    addPredefinedTip,
    removePredefinedTip,
  } = props

  const [newPredefinedTip, setNewPredefinedTip] = useState<number>(0)

  return (
    <TableContainer component={Paper}>
      <Table sx={{ maxWidth: 300 }}>
        <TableBody>
          {predefinedTips?.map((tip: number, index: number) => (
            <TableRow
              key={`predefined-tip-${index}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                <div className={cx(classes.tipDisplay)}>
                  {tipType === TipType.Flat ? toDollars(tip) : tip}
                </div>
              </TableCell>
              <TableCell className={cx(classes.actions)}>
                <DeleteConfirmationButton
                  disabled={disabled}
                  onConfirmAction={() => removePredefinedTip(paymentService, index)}
                  title="Are you sure you want to delete this tip?"
                  errorMessage="Failed to delete tip"
                  successMessage="Tip deleted"
                />
              </TableCell>
            </TableRow>
          ))}
          {predefinedTips.length < maxPredefinedTips && (
            <TableRow
              key="new-predefined-tip"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell className={cx(classes.percentageInput)}>
                {tipType === TipType.Percentage && (
                  <PercentInput
                    decimalScale={0}
                    max={maxTipPercentage}
                    disabled={disabled}
                    value={newPredefinedTip}
                    onChange={(value) => {
                      setNewPredefinedTip(value as number)
                    }}
                  />
                )}
                {tipType === TipType.Flat && (
                  <CurrencyInput
                    disabled={disabled}
                    value={newPredefinedTip}
                    onChange={(value) => {
                      setNewPredefinedTip(value as number)
                    }}
                  />
                )}
              </TableCell>
              <TableCell className={cx(classes.actions)}>
                <LoadingButton
                  variant="contained"
                  type="button"
                  disabled={disabled}
                  onClick={async () => {
                    await addPredefinedTip(paymentService, newPredefinedTip)
                    setNewPredefinedTip(0)
                  }}
                >
                  Add
                </LoadingButton>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const useStyles = makeStyles()(() => ({
  container: {
    padding: '0',
    display: 'flex',
    rowGap: '16px',
    columnGap: '32px',
    flexDirection: 'column',
  },
  fieldsContainer: {
    display: 'flex',
    gap: '64px',
  },
  togglesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    maxWidth: '50%',
  },
  toggleFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
  },
  predefinedTipsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tipTypeContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    alignSelf: 'flex-start',
    maxWidth: '200px',
  },
  tipTypeField: {
    maxWidth: '56px',
  },
  percentageInput: {
    width: '98px',
  },
  tipDisplay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #9EA0A5',
    borderRadius: '4px',
    height: '40px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  },
  noTipsWarning: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
  },
  noTipsTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
}))
