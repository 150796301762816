import { useState } from 'react'
import { useToast } from '@/hooks/useToast'
import { IconButton, Typography } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { TBasicType, TPaymentService } from '@/models'
import { makeStyles } from '@/theme'
import { Switch } from '@/components/Switch'
import { usePaymentServiceListElement } from '@/hooks/usePaymentServicesList'

export interface IConfigurationEntryProps {
  name: string
  label?: string
  value: TBasicType
  paymentService: TPaymentService
}

export const ConfigurationEntry = (props: IConfigurationEntryProps) => {
  const { classes, cx } = useStyles()
  const toast = useToast()

  const [isToggleChecked, setIsToggleChecked] = useState<boolean>(!!props.value)

  const {
    state: { loading },
    actions: { toggleField },
  } = usePaymentServiceListElement(props.paymentService)

  const handleCopy = () => {
    void navigator.clipboard.writeText(`${props.value}`)
    toast.showMessage(`${props.label} copied.`, 'info')
  }

  const handleToggleChange = () => {
    toggleField(props.name)
      .then((res) => {
        setIsToggleChecked(res.data?.[props.name])
        toast.showMessage(`${props.label} updated.`, 'info')
      })
      .catch((error) => {
        toast.showMessage(error, 'info')
      })
  }

  return (
    <div>
      {props.label && (
        <Typography variant="subtitle1" color="text.secondary" sx={{ fontSize: '14px' }}>
          {props.label}
        </Typography>
      )}
      {typeof props.value === 'boolean' ? (
        <Switch checked={isToggleChecked} disabled={loading} onChange={handleToggleChange} />
      ) : (
        <div className={cx(classes.valueContainer)}>
          <Typography variant="subtitle2" color="text.primary" className={cx(classes.value)}>
            {props.value || 'Not Defined'}
          </Typography>
          {props.value && (
            <IconButton onClick={handleCopy}>
              <ContentCopyIcon sx={{ fontSize: '20px' }} />
            </IconButton>
          )}
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles()(() => ({
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  value: {
    whiteSpace: 'nowrap',
    maxWidth: '250px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))
