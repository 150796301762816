import { Typography } from '@mui/material'
import { makeStyles } from '@/theme'

export function WithDescription({ description, descriptionOnBottom, children }) {
  const { classes, cx } = useStyles(descriptionOnBottom)

  if (!description) return children

  return (
    <div className={cx(classes.container)}>
      <Typography variant="subtitle2" color="text.secondary" className={cx(classes.description)}>
        {description}
      </Typography>
      {children}
    </div>
  )
}

const useStyles = makeStyles()((descriptionOnBottom) => ({
  container: {
    display: 'flex',
    flexDirection: descriptionOnBottom ? 'column-reverse' : 'row',
    alignItems: descriptionOnBottom ? 'flex-start' : 'center',
    gap: descriptionOnBottom ? 0 : '10px',
  },
  description: {
    fontSize: '12px',
    paddingLeft: descriptionOnBottom && '58px',
  },
}))
