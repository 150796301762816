import { TAnyError, IPaymentOption } from '@/models'
import { atom } from 'recoil'

export interface PaymentOptionListState {
  loading: boolean
  error?: TAnyError | null
  data: IPaymentOption[] | null
}

export const paymentOptionsList = atom<PaymentOptionListState>({
  key: 'PaymentOptionsList',
  default: {
    loading: false,
    error: null,
    data: null,
  },
})
