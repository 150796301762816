export function toCents(amount: string): number {
  const [integer, decimal] = amount.split('.')

  return parseInt(`${integer}${(decimal || '').slice(0, 2).padEnd(2, '0')}`)
}

export function toDollars(cents: number) {
  const centsStr = cents.toString()
  const fraction = centsStr.slice(-2).padStart(2, '0')
  const integer = centsStr.slice(0, -2) || '0'

  if (fraction === '00') return integer

  return `${integer}.${fraction}`
}

export function formatMoney(cents: number) {
  const formatter = new Intl.NumberFormat(getLocale(), {
    minimumFractionDigits: 2,
  })
  return `$${formatter.format(Number(toDollars(cents)))}`
}

function getLocale() {
  if (navigator.languages != undefined) return navigator.languages[0]
  return navigator.language
}
