import PaymentIcon from '@mui/icons-material/Payment'
import { Typography } from '@mui/material'
import { makeStyles } from '@/theme'

interface IEmptyPlaceholderProps {
  title: string
  description: string
  hideIcon?: boolean
}
export function EmptyPlaceholder(props: IEmptyPlaceholderProps) {
  const { classes, cx } = useEmptyPlaceholderStyles()

  return (
    <div className={cx(classes.container)}>
      {!props.hideIcon && <PaymentIcon fontSize={'large'} />}
      <Typography variant="h2" color="text.primary">
        {props.title}
      </Typography>
      <Typography variant="subtitle2" color="text.secondary">
        {props.description}
      </Typography>
    </div>
  )
}

const useEmptyPlaceholderStyles = makeStyles()(() => ({
  container: {
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',

    rowGap: '8px',
  },
}))
