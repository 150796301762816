import { Typography } from '@mui/material'
import { makeStyles } from '@/theme'
import { getErrorMessages } from '@/helpers/errors'

interface IErrorMessageProps {
  title?: string
  error?: object | string
}

const defaultTitle = 'Oops! An error has occurred :('
export function ErrorMessage(props: IErrorMessageProps) {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.container)}>
      <Typography className={cx(classes.title)} variant="h6" color="red" gutterBottom>
        {props.title || defaultTitle}
      </Typography>

      {getErrorMessages(props.error).map((msg, index) => (
        <Typography
          key={index}
          className={cx(classes.description)}
          color="text.secondary"
          gutterBottom
        >
          {msg}
        </Typography>
      ))}
    </div>
  )
}

const useStyles = makeStyles()(() => ({
  container: {},
  title: {},
  description: {},
}))
