import { ReactElement } from 'react'
import { FormHelperText, InputLabel } from '@mui/material'
import { makeStyles } from '@/theme'

interface IFormFieldProps {
  label: string
  component: ReactElement
  helperText?: string | boolean
  error?: boolean
}

export function FormField(props: IFormFieldProps) {
  const { classes, cx } = useStyles()
  return (
    <div style={{ width: '100%' }}>
      <InputLabel className={cx(classes.label)}>{props.label}</InputLabel>
      {props.component}
      {props.helperText && <FormHelperText error={props.error}>{props.helperText}</FormHelperText>}
    </div>
  )
}

const useStyles = makeStyles()((theme) => ({
  label: {
    textTransform: 'uppercase',
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginBottom: '8px',
    fontSize: '12px',
  },
}))
