import { MultiMerchantApp } from '@/app/customer/MultiMerchantApp'
import { SingleMerchantApp } from '@/app/customer/SingleMerchantApp'

export function CustomerApp(props) {
  if (props.locations) {
    return <MultiMerchantApp {...props} locations={props.locations} />
  } else {
    return <SingleMerchantApp {...props} />
  }
}
