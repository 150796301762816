import { useEffect, useState } from 'react'
import { CircularProgress, FormControl, InputLabel, Select } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { LoadingButton } from '@mui/lab'
import { makeStyles } from '@/theme'
import { ErrorMessage } from '@/components/ErrorMessage'
import { usePaymentServiceLocations } from '@/hooks/usePaymentServiceLocations'
import { EmptyPlaceholder } from '@/components/EmptyPlaceholder'
import { useToast } from '@/hooks/useToast'

interface ILocationSetupSectionProps {
  provider: string
}

export const LocationSetup = (props: ILocationSetupSectionProps) => {
  const { classes, cx } = useStyles()
  const toast = useToast()

  const { provider } = props

  const [selectedLocation, setSelectedLocation] = useState<string | null>()

  const {
    state: {
      data: { availableClientIds: locations, selectedClientId },
      loading,
      error,
    },
    actions: { fetchLocations, updateLocation },
  } = usePaymentServiceLocations(provider)

  useEffect(() => {
    void fetchLocations().then((locations) => {
      setSelectedLocation(locations.clientId)
    })
  }, [])

  useEffect(() => {
    setSelectedLocation(selectedClientId)
  }, [selectedClientId])

  const onChangeLocation = (evt) => {
    setSelectedLocation(evt.target.value)
  }

  const saveChanges = () => {
    if (selectedLocation)
      updateLocation(selectedLocation).then(() => {
        toast.showMessage('Location updated', 'info')
      })
  }

  if (error) {
    return <ErrorMessage error={error} />
  }

  if (loading) {
    return <CircularProgress />
  }

  if (locations.length === 0) {
    return (
      <div
        style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', rowGap: '24px' }}
      >
        <EmptyPlaceholder
          title={'No available locations to be configured'}
          description={'At the moment there are no locations to be configured'}
        />
      </div>
    )
  }

  return (
    <div className={cx(classes.container)}>
      <FormControl fullWidth>
        <InputLabel id="locations-label">Locations</InputLabel>
        <Select
          key={`select-with-${selectedLocation}`}
          labelId="locations-label"
          id="locations"
          value={selectedLocation}
          label="Locations"
          onChange={onChangeLocation}
        >
          {locations.map((location) => (
            <MenuItem key={location.id} value={location.id}>
              {location.id} - {location.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <LoadingButton
        className={cx(classes.saveButton)}
        variant="contained"
        loading={loading}
        disabled={!selectedLocation}
        onClick={saveChanges}
      >
        Save
      </LoadingButton>
    </div>
  )
}

const useStyles = makeStyles()(() => ({
  container: {
    padding: '0',
    display: 'flex',
    rowGap: '16px',
    columnGap: '32px',
    flexDirection: 'column',
  },
  saveButton: {
    width: '100px',
  },
}))
