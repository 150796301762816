export const routes = {
  home: () => '/',
  connectPaymentService: providerRoute('/connect'),
  editPaymentService: providerRoute('/edit'),
  newTerminal: providerRoute('/terminals/new'),
  newFee: providerRoute('/fees/new'),
  editFee: providerRoute('/fees/edit'),
  paymentServiceDetail: providerRoute(),
  error: () => '/error',

  // admin routes
  adminPaymentOptionCreation: () => `/payment-options/create`,
  adminPaymentOptionEdit: (provider) => `/payment-options/${provider}/edit`,
  adminPaymentOptionsList: () => `/payment-options`,
}

function providerRoute(route = '') {
  return (provider) => `/${provider}${route}`
}
