import * as React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import singleSpaReact from 'single-spa-react'
import { Root } from './root.component'

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: Root,
  renderType: 'createRoot',
})

export const { bootstrap, mount, unmount } = lifecycles
