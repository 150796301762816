import { atomFamily } from 'recoil'
import { EPaymentConfigurationStatus, IPaymentServiceConfiguration, TAnyError } from '@/models'
import { IConfigurationApprovalFilters } from '@/services/types'

export interface PaymentConfigurationApprovalsListState {
  loading: boolean
  hasNextPage: boolean
  filters?: IConfigurationApprovalFilters | null
  error?: TAnyError | null
  data: IPaymentServiceConfiguration[]
}

export const paymentConfigurationApprovalsListState = atomFamily<
  PaymentConfigurationApprovalsListState,
  string | undefined
>({
  key: 'PaymentConfigurationApprovalsListState',
  default: {
    loading: false,
    hasNextPage: false,
    error: null,
    filters: { status: [EPaymentConfigurationStatus.PENDING_APPROVAL] },
    data: [],
  },
})

export interface PaymentConfigurationApprovalState {
  loading: boolean
  error?: TAnyError | null
}

export const paymentConfigurationApprovalState = atomFamily<
  PaymentConfigurationApprovalState,
  string
>({
  key: 'PaymentConfigurationApprovalState',
  default: {
    loading: false,
    error: null,
  },
})
