import { Input } from '@/components/Input'
import { NumericFormat } from 'react-number-format'
import { toCents, toDollars } from '@/helpers/currency'

interface ICurrencyInputProps {
  disabled?: boolean
  allowNullable?: boolean
  value?: number | null
  onChange: (value: number | null) => void
}

export function CurrencyInput(props: ICurrencyInputProps) {
  return (
    <NumericFormat
      disabled={props.disabled}
      thousandSeparator={','}
      decimalSeparator={'.'}
      allowNegative={false}
      allowLeadingZeros={false}
      prefix={'$'}
      decimalScale={2}
      customInput={Input}
      onValueChange={(values) => {
        props.allowNullable && !values.value
          ? props.onChange(null)
          : props.onChange(toCents(values.value))
      }}
      value={typeof props.value === 'number' ? toDollars(props.value) : null}
    />
  )
}
