import { RichTextReadOnly } from 'mui-tiptap'
import { BulletList } from '@tiptap/extension-bullet-list'
import { Document } from '@tiptap/extension-document'
import { ListItem } from '@tiptap/extension-list-item'
import { OrderedList } from '@tiptap/extension-ordered-list'
import { Paragraph } from '@tiptap/extension-paragraph'
import { Text } from '@tiptap/extension-text'
import { Link } from '@tiptap/extension-link'
import { makeStyles } from '@/theme'

const extensions = [BulletList, Document, Link, ListItem, OrderedList, Paragraph, Text]

export const AboutSection = (props: { text: string; connected?: boolean }) => {
  const { classes, cx } = useAboutStyles()

  return (
    <div className={cx(classes.container)}>
      <RichTextReadOnly content={props.text} extensions={extensions} />
    </div>
  )
}

const useAboutStyles = makeStyles()((theme) => ({
  container: {
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    color: theme.palette.text.secondary,
    '*': {
      fontFamily: theme.typography.subtitle2.fontFamily,
      fontWeight: theme.typography.subtitle2.fontWeight,
      fontSize: theme.typography.subtitle2.fontSize,
      letterSpacing: theme.typography.subtitle2.letterSpacing,
      lineHeight: theme.typography.subtitle2.lineHeight,
    },
  },
  aboutEntryContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    columnGap: '8px',
  },
}))
