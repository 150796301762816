import { SwitchProps, Switch as MuiSwitch } from '@mui/material'

export function Switch(props: SwitchProps) {
  // These styles are needed to prevent styles for the host app from breaking the component.
  return (
    <MuiSwitch
      sx={{
        '& .MuiSwitch-input': {
          width: '100% !important',
          height: '100% !important',
          left: 'unset !important',
        },
      }}
      {...props}
    />
  )
}
