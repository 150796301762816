import { ReactElement } from 'react'
import { FormHelperText, InputLabel } from '@mui/material'
import { makeStyles } from '@/theme'
import { FormikProps } from 'formik'
import { EFormFieldType } from '@/models'
import { WithDangerNote } from '@/components/forms/Form/decorators/WithDangerNote'
import { WithDescription } from '@/components/forms/Form/decorators/WithDescription'

interface IFormFieldProps<T = unknown> {
  label: string
  fieldType: EFormFieldType | ((params: { formik?: FormikProps<T> }) => EFormFieldType)
  component: ReactElement
  helperText?: string | boolean
  error?: boolean
  description?: string
  descriptionOnBottom?: boolean
  dangerNote?: string | null
}

interface IFieldProps {
  label: string
  component: ReactElement
  isInline: boolean
}

export function FormField(props: IFormFieldProps) {
  const isInline = props.fieldType === EFormFieldType.BOOLEAN

  return (
    <div style={{ width: '100%' }}>
      <WithDangerNote text={props.dangerNote}>
        <WithDescription
          description={props.description}
          descriptionOnBottom={props.descriptionOnBottom}
        >
          <Field label={props.label} component={props.component} isInline={isInline} />
          {props.helperText && (
            <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
          )}
        </WithDescription>
      </WithDangerNote>
    </div>
  )
}

function Field(props: IFieldProps) {
  const { classes, cx } = useStyles()
  const { label, component, isInline } = props

  if (isInline) {
    return (
      <div className={cx(classes.inlineContainer)}>
        {component}
        <InputLabel className={cx(classes.inlineLabel)}>{label}</InputLabel>
      </div>
    )
  }

  return (
    <div>
      <InputLabel className={cx(classes.label)}>{label}</InputLabel>
      {component}
    </div>
  )
}

const useStyles = makeStyles()((theme) => ({
  inlineContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  inlineLabel: {
    textTransform: 'uppercase',
    fontWeight: 500,
    color: theme.palette.text.secondary,
    fontSize: '12px',
    marginBottom: '0',
  },
  label: {
    textTransform: 'uppercase',
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginBottom: '8px',
    fontSize: '12px',
  },
}))
