import { selectorFamily, useRecoilValueLoadable } from 'recoil'
import { ITerminalOption } from '@/models'
import { RecoilConstants } from '@/constants'
import { appConfigState } from '@/state/appConfig'

const terminalOptions = selectorFamily({
  key: 'terminalOptions',
  get:
    (provider?: string) =>
    async ({ get }) => {
      if (!provider) return []
      const config = get(appConfigState)
      const PaymentServiceTerminals = config.factories.paymentServiceTerminals
      return PaymentServiceTerminals().fetchTerminalOptions(provider)
    },
})

export function useTerminalOptions(provider?: string): { terminalOptions: ITerminalOption[] } {
  const { state, contents } = useRecoilValueLoadable(terminalOptions(provider))

  return {
    terminalOptions: state === RecoilConstants.HAS_VALUE ? contents : [],
  }
}
