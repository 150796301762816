import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CardContent, CircularProgress, Typography } from '@mui/material'
import { usePaymentService } from '@/hooks/usePaymentService'
import { ConnectPaymentServiceForm } from '@/components/forms/ConnectPaymentServiceForm'
import { FormModal } from '@/components/FormModal'
import { PaymentConfigurationStatusMessages } from '@/constants'
import { makeStyles } from '@/theme'
import { EPaymentConfigurationStatus } from '@/models'
import { PaymentServiceApprovalStatusBadge } from '@/components/PaymentServiceApprovalStatusBadge'
import { WithDiscardChangesConfirmationDialog } from '@/components/WithDiscardChangesConfirmationDialog'
import { routes } from '@/helpers/routes'

export const PaymentServiceConnectPage = () => {
  const { provider } = useParams()
  const {
    state: { loading, data: paymentService },
    actions,
  } = usePaymentService(provider)

  const navigate = useNavigate()
  const closeModal = () => {
    if (paymentService?.connected)
      navigate(routes.paymentServiceDetail(provider)) // user was editing the configuration
    else navigate(routes.home()) // user was creating a new configuration
  }

  const hasPaymentService = provider && paymentService?.provider === provider

  const title = hasPaymentService
    ? `Connect to ${paymentService?.paymentOptionDisplayName || paymentService?.provider}`
    : 'Connect'

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const message = 'Any unsaved configuration changes will be lost'
      event.returnValue = message
      return message
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  return (
    <WithDiscardChangesConfirmationDialog onConfirm={closeModal}>
      {({ formRef, requestConfirmation }) => (
        <FormModal title={title} onClose={requestConfirmation}>
          {hasPaymentService && (
            <>
              <PaymentConfigurationApprovalStatus paymentService={paymentService} />
              <ConnectPaymentServiceForm
                paymentService={paymentService}
                fields={paymentService.fields}
                onSubmit={actions.connect}
                onSuccess={closeModal}
                onCancel={requestConfirmation}
                ref={formRef}
              />
            </>
          )}
          {!hasPaymentService && loading && (
            <CardContent
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </CardContent>
          )}
        </FormModal>
      )}
    </WithDiscardChangesConfirmationDialog>
  )
}

function PaymentConfigurationApprovalStatus({ paymentService }) {
  const { cx, classes } = useStyles()
  const message = PaymentConfigurationStatusMessages[paymentService.status]

  if (!message) return null

  return (
    <CardContent className={cx(classes.content)}>
      <PaymentServiceApprovalStatusBadge status={paymentService.status} />
      <Typography
        variant="subtitle2"
        color={paymentService.status === EPaymentConfigurationStatus.DENIED ? 'error' : 'primary'}
      >
        {message}
      </Typography>
    </CardContent>
  )
}

const useStyles = makeStyles()(() => ({
  content: {
    padding: '24px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
}))
