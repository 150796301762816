import { useAppConfig } from '@/hooks/useAppConfig'
import { useNavigate } from 'react-router-dom'

export function BaseApp({ children, ...props }) {
  const { config } = useAppConfig()
  const navigate = useNavigate()
  config.init({ ...props, router: { navigate } })

  return children
}
