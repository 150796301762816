import { RecoilRoot } from 'recoil'
import { ScopedCssBaseline, ThemeProvider } from '@mui/material'
import { lightTheme } from '@/theme'
import { ToastContextProvider } from '@/components/Toast'

export function AppProvider({ RouterComponent, basename, children }) {
  return (
    <RecoilRoot>
      <ThemeProvider theme={lightTheme}>
        <ToastContextProvider>
          <ScopedCssBaseline>
            <RouterComponent basename={basename || ''}>{children}</RouterComponent>
          </ScopedCssBaseline>
        </ToastContextProvider>
      </ThemeProvider>
    </RecoilRoot>
  )
}
