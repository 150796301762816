import { BrowserRouter } from 'react-router-dom'
import { AppProvider } from '@/app/common/AppProvider'
import { BaseApp } from '@/app/common/BaseApp'
import { AdminAppRoutes } from '@/app/admin/AdminAppRoutes'

export function AdminApp(props) {
  return (
    <AppProvider RouterComponent={BrowserRouter} basename={props.basename}>
      <BaseApp {...props}>
        <AdminAppRoutes />
      </BaseApp>
    </AppProvider>
  )
}
