import { useNavigate, useParams } from 'react-router-dom'
import { FormModal } from '@/components/FormModal'
import { FeeConfigurationForm } from '@/components/forms/FeeConfigurationForm'
import { usePaymentServiceFee } from '@/hooks/usePaymentServiceFees'
import { usePaymentService } from '@/hooks/usePaymentService'
import { routes } from '@/helpers/routes'

export const FeeConfigurationPage = ({ fee }) => {
  const { provider } = useParams()
  const {
    state: {
      hasSurchargeFeesOption,
      isShopFlatSurchargeFeesEnabled,
      isShopPercentageSurchargeFeesEnabled,
      hasSurchargeFeeAdded,
      hasNonSurchargeFeeAdded,
      hasManualFeesOption,
      isShopFlatManualFeesEnabled,
      isShopPercentageManualFeesEnabled,
    },
    actions,
  } = usePaymentServiceFee(provider || '')

  const {
    actions: { fetchPaymentService },
  } = usePaymentService(provider || '')

  const navigate = useNavigate()
  const closeModal = () => navigate(routes.paymentServiceDetail(provider))
  const onSuccess = () => {
    closeModal()
    void fetchPaymentService(provider || '')
  }

  return (
    <FormModal title={'Fee Configuration'} onClose={closeModal}>
      <FeeConfigurationForm
        fee={fee}
        onSubmit={fee ? actions.updateFee : actions.insertFee}
        onSuccess={onSuccess}
        onCancel={closeModal}
        hasSurchargeFeesOption={hasSurchargeFeesOption}
        isShopFlatSurchargeFeesEnabled={isShopFlatSurchargeFeesEnabled}
        isShopPercentageSurchargeFeesEnabled={isShopPercentageSurchargeFeesEnabled}
        hasSurchargeFeeAdded={hasSurchargeFeeAdded}
        hasNonSurchargeFeeAdded={hasNonSurchargeFeeAdded}
        hasManualFeesOption={hasManualFeesOption}
        isShopFlatManualFeesEnabled={isShopFlatManualFeesEnabled}
        isShopPercentageManualFeesEnabled={isShopPercentageManualFeesEnabled}
      />
    </FormModal>
  )
}
