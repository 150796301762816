import { atomFamily } from 'recoil'
import { IClientIds, TAnyError } from '@/models'

export interface IPaymentServiceLocationsState {
  loading: boolean
  error?: TAnyError | null
  data: IClientIds
}

export const paymentServiceLocationsState = atomFamily<IPaymentServiceLocationsState, string>({
  key: 'PaymentServiceLocations',
  default: {
    loading: false,
    error: null,
    data: {
      availableClientIds: [],
      selectedClientId: undefined,
    },
  },
})
