import { makeStyles } from '@/theme'
import { FeeType, IFee, TPaymentService } from '@/models'
import { EmptyPlaceholder } from '@/components/EmptyPlaceholder'
import { routes } from '@/helpers/routes'
import { Button, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { ErrorMessage } from '@/components/ErrorMessage'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined'
import { formatMoney } from '@/helpers/currency'
import { DeleteConfirmationButton } from '@/components/DeleteConfirmationButton'
import { usePaymentServiceFee } from '@/hooks/usePaymentServiceFees'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'

interface IFeeSetupSectionProps {
  paymentService: TPaymentService
}

export const FeeSetupSection = (props: IFeeSetupSectionProps) => {
  const { classes, cx } = useStyles()

  const provider = props.paymentService.provider

  const {
    state: { fees, error, loading },
    actions: feeActions,
  } = usePaymentServiceFee(provider)

  if (error) {
    return <ErrorMessage error={error} />
  }

  if (loading) {
    return <CircularProgress />
  }

  if (fees.length === 0) {
    return (
      <div
        style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', rowGap: '24px' }}
      >
        <EmptyPlaceholder
          title={'No fees configured'}
          description={'At the moment you have no fees configured'}
        />
        <AddFeeButton paymentService={props.paymentService} sx={{ alignSelf: 'center' }} />
      </div>
    )
  }

  return (
    <div className={cx(classes.container)}>
      <div className={cx(classes.headerContainer)}>
        <FeeDisclaimer />

        <AddFeeButton
          paymentService={props.paymentService}
          sx={{ alignSelf: 'self-end' }}
          disabled
          disabledMessage={'You can only have at most one fee.'}
        />
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Fee Name</TableCell>
              <TableCell>Fee Amount</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {fees.map((fee) => {
              const formattedFee = formatFee(fee)
              return (
                <TableRow key={fee.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{formattedFee.feeType}</TableCell>
                  <TableCell>{fee.name}</TableCell>
                  <TableCell>{formattedFee.formattedAmount}</TableCell>
                  <TableCell>
                    <EditFeeButton paymentService={props.paymentService} fee={fee} />
                    <DeleteConfirmationButton
                      onConfirmAction={() => feeActions.deleteFee(fee)}
                      title="Are you sure you want to delete this fee?"
                      text="The selected fee will be permanently deleted"
                      errorMessage="Failed to update fee"
                      successMessage="Fee deleted"
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

function EditFeeButton({ paymentService, fee }) {
  const navigate = useNavigate()
  const location = useLocation()

  const onClickEditFee = () => {
    navigate(routes.editFee(paymentService.provider), {
      state: { previousLocation: location, fee: fee },
    })
  }

  return (
    <IconButton onClick={onClickEditFee}>
      <BorderColorOutlinedIcon sx={{ fontSize: '24px' }} />
    </IconButton>
  )
}

function AddFeeButton({ paymentService, sx, disabled = false, disabledMessage = '' }) {
  const navigate = useNavigate()
  const location = useLocation()

  const onClickNewFee = () => {
    navigate(routes.newFee(paymentService.provider), {
      state: { previousLocation: location },
    })
  }

  return (
    <Tooltip title={disabled ? disabledMessage : ''} followCursor>
      <div style={sx}>
        <Button
          disabled={disabled}
          onClick={onClickNewFee}
          variant={'contained'}
          size={'large'}
          type={'button'}
        >
          Add Fee
        </Button>
      </div>
    </Tooltip>
  )
}

function FeeDisclaimer() {
  const { cx, classes } = useStyles()

  return (
    <div className={cx(classes.feeDisclaimerContainer)}>
      <div>
        <WarningRoundedIcon color={'error'} fontSize={'large'} />
      </div>
      <div>
        <Typography variant="subtitle1" color="text.primary">
          Please consult with legal or tax professionals for guidance to ensure fee configurations
          are federally and locally compliant.
        </Typography>
      </div>
    </div>
  )
}

function formatFee(fee: IFee): {
  feeType: string
  formattedAmount: string
} {
  const { feeType, amount } = fee

  const feeTypeKey = Object.keys(FeeType).find((key) => FeeType[key] === feeType) || ''

  let formattedAmount: string

  switch (feeType) {
    case FeeType.Percentage:
      formattedAmount = `${amount.toFixed(2)}%`
      break
    case FeeType.Flat:
      formattedAmount = formatMoney(amount)
      break
    default:
      formattedAmount = '-'
  }

  return { feeType: feeTypeKey, formattedAmount }
}

const useStyles = makeStyles()(() => ({
  container: {
    padding: '0',
    display: 'flex',
    rowGap: '16px',
    columnGap: '32px',
    flexDirection: 'column',
  },
  feeDisclaimerContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    maxWidth: 1024,
  },
  headerContainer: { display: 'flex', justifyContent: 'space-between' },
}))
