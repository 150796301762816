import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button } from '@mui/material'
import { makeStyles } from '@/theme'

export function BackButton(props: { onClick: () => void }) {
  const { classes, cx } = useStyles()

  return (
    <Button variant="text" onClick={props.onClick} className={cx(classes.button)}>
      <ArrowBackIcon sx={{ fontSize: '24px' }} />
      <span>Back</span>
    </Button>
  )
}

const useStyles = makeStyles()((theme) => ({
  button: {
    color: theme.palette.text.primary,
    columnGap: '8px',
    padding: 0,
  },
}))
