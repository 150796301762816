import * as yup from 'yup'
import { buildSchemaObject } from '@/helpers/formBuilder'
import { useFormik } from 'formik'
import {
  EFormFieldType,
  FeeCategory,
  feeCategoryMap,
  FeeType,
  IAsyncRequestResult,
  IFee,
} from '@/models'
import { getErrorMessages, getFieldErrors } from '@/helpers/errors'
import { useToast } from '@/hooks/useToast'
import { BaseForm } from '@/components/forms/Form'
import { trimFormValues } from '@/helpers/trimFormValues'
import { isNumber } from '@/helpers/isNumber'
import { useEffect, useState } from 'react'
import { HttpStatusCode } from 'axios'
import { AppConstants } from '@/constants'
import { capitalizeFirstLetters } from '@/helpers/strings'

const feeTypeMap = {
  Flat: '$',
  Percentage: '%',
}

interface IFeeConfigurationFormProps {
  onCancel: () => void
  onSuccess: () => void
  onSubmit: (fee: IFee) => Promise<IAsyncRequestResult<IFee>>
  fee: IFee
  hasSurchargeFeesOption: boolean
  isShopFlatSurchargeFeesEnabled: boolean
  isShopPercentageSurchargeFeesEnabled: boolean
  hasSurchargeFeeAdded: boolean
  hasNonSurchargeFeeAdded: boolean
  hasManualFeesOption: boolean
  isShopFlatManualFeesEnabled: boolean
  isShopPercentageManualFeesEnabled: boolean
}

export function FeeConfigurationForm(props: IFeeConfigurationFormProps) {
  const toast = useToast()
  const {
    fee,
    hasSurchargeFeesOption,
    isShopFlatSurchargeFeesEnabled,
    isShopPercentageSurchargeFeesEnabled,
    hasSurchargeFeeAdded,
    hasNonSurchargeFeeAdded,
    hasManualFeesOption,
    isShopFlatManualFeesEnabled,
    isShopPercentageManualFeesEnabled,
  } = props

  const isEditing = !!fee

  const [formFields, setFormFields] = useState(
    getFormFields({
      isEditing,
      hasSurchargeFeesOption,
      isShopFlatSurchargeFeesEnabled,
      isShopPercentageSurchargeFeesEnabled,
      hasManualFeesOption,
      isShopFlatManualFeesEnabled,
      isShopPercentageManualFeesEnabled,
    }),
  )

  const [validationSchema, setValidationSchema] = useState(
    yup.object(buildSchemaObject(formFields)),
  )

  useEffect(() => {
    if (!isEditing) {
      if (isShopFlatSurchargeFeesEnabled) formik.setFieldValue('feeType', FeeType.Flat)
      formik.setFieldValue(
        'feeCategory',
        hasManualFeesOption && (hasSurchargeFeeAdded || !hasNonSurchargeFeeAdded)
          ? FeeCategory.MANUAL
          : FeeCategory.SURCHARGE,
      )
    }
    setValidationSchema(yup.object(buildSchemaObject(formFields)))
  }, [formFields])

  const formik = useFormik<IFee>({
    initialValues: {
      id: fee?.id,
      name:
        fee?.name ||
        (hasManualFeesOption && capitalizeFirstLetters(feeCategoryMap[FeeCategory.MANUAL])) ||
        (hasSurchargeFeesOption && capitalizeFirstLetters(feeCategoryMap[FeeCategory.SURCHARGE])) ||
        '',
      feeType: fee?.feeType,
      feeCategory: fee?.feeCategory,
      amount: fee?.amount,
      hasSurchargeFeeAdded,
      hasSurchargeFeesOption,
    },
    validationSchema,
    onSubmit: (values) => {
      return props
        .onSubmit(trimFormValues(values))
        .then((result) => {
          if (result.error) {
            result.error.errors && formik.setErrors(getFieldErrors(result.error))

            const errorMsg =
              hasSurchargeFeesOption &&
              ((hasSurchargeFeeAdded && values.feeCategory === FeeCategory.SURCHARGE) ||
                (!hasSurchargeFeeAdded && values.feeCategory === FeeCategory.MANUAL))
                ? `Fee with fee category ${values.feeCategory} already exists`
                : 'Fee name already exists'

            const errorMessages = [
              'Failed to update fee',
              ...getErrorMessages(result.error, ({ status, detail }) =>
                status === HttpStatusCode.Conflict ? errorMsg : detail,
              ),
            ]

            toast.showMessage(errorMessages.join('\n'), 'error')
          } else {
            toast.showMessage('Fee updated', 'success')
            props.onSuccess()
          }
        })
        .catch((e) => {
          if (!e?.message) toast.showMessage('Failed to update fee', 'error')

          toast.showMessage(e.message, 'error')
        })
    },
  })

  useEffect(() => {
    if (!fee?.amount) formik.setFieldValue('amount', 0)
  }, [formik.values.feeType])

  useEffect(() => {
    const isDisabledFee =
      isEditing &&
      ((fee.feeCategory === FeeCategory.MANUAL && !hasManualFeesOption) ||
        (fee.feeCategory === FeeCategory.SURCHARGE && !hasSurchargeFeesOption) ||
        (fee.feeCategory === FeeCategory.MANUAL &&
          ((fee.feeType === FeeType.Flat && !isShopFlatManualFeesEnabled) ||
            (fee.feeType === FeeType.Percentage && !isShopPercentageManualFeesEnabled))) ||
        (fee.feeCategory === FeeCategory.SURCHARGE &&
          ((fee.feeType === FeeType.Flat && !isShopFlatSurchargeFeesEnabled) ||
            (fee.feeType === FeeType.Percentage && !isShopPercentageSurchargeFeesEnabled))))

    if (!isDisabledFee && formik.values.feeCategory === FeeCategory.MANUAL) {
      if (!formik.values.amount) formik.setFieldValue('amount', 0)
      if (isShopFlatManualFeesEnabled) {
        formik.setFieldValue('feeType', FeeType.Flat)
      } else if (isShopPercentageManualFeesEnabled) {
        formik.setFieldValue('feeType', FeeType.Percentage)
      }
    }
    if (!isDisabledFee && formik.values.feeCategory === FeeCategory.SURCHARGE) {
      if (!fee?.amount) formik.setFieldValue('amount', 0)
      if (isShopFlatSurchargeFeesEnabled) {
        formik.setFieldValue('feeType', FeeType.Flat)
      } else if (isShopPercentageSurchargeFeesEnabled) {
        formik.setFieldValue('feeType', FeeType.Percentage)
      }
    }

    if (!formik.values.name && formik.values.feeCategory === FeeCategory.MANUAL) {
      formik.setFieldValue('name', feeCategoryMap[FeeCategory.MANUAL])
    }

    if (!formik.values.name && formik.values.feeCategory === FeeCategory.SURCHARGE) {
      formik.setFieldValue('name', feeCategoryMap[FeeCategory.SURCHARGE])
    }
  }, [formik.values.feeCategory])

  return <BaseForm formikInstance={formik} onCancel={props.onCancel} formFields={formFields} />
}

const getFormFields = ({
  isEditing,
  hasSurchargeFeesOption,
  isShopFlatSurchargeFeesEnabled,
  isShopPercentageSurchargeFeesEnabled,
  hasManualFeesOption,
  isShopFlatManualFeesEnabled,
  isShopPercentageManualFeesEnabled,
}) => {
  const isDisabledFee = (formik) => {
    return (
      isEditing &&
      ((formik.values.feeCategory === FeeCategory.MANUAL && !hasManualFeesOption) ||
        (formik.values.feeCategory === FeeCategory.SURCHARGE && !hasSurchargeFeesOption) ||
        (formik.values.feeCategory === FeeCategory.MANUAL &&
          ((formik.values.feeType === FeeType.Flat && !isShopFlatManualFeesEnabled) ||
            (formik.values.feeType === FeeType.Percentage &&
              !isShopPercentageManualFeesEnabled))) ||
        (formik.values.feeCategory === FeeCategory.SURCHARGE &&
          ((formik.values.feeType === FeeType.Flat && !isShopFlatSurchargeFeesEnabled) ||
            (formik.values.feeType === FeeType.Percentage &&
              !isShopPercentageSurchargeFeesEnabled))))
    )
  }
  return [
    {
      label: 'Fee Name',
      placeholder: 'Insert fee name',
      type: EFormFieldType.STRING,
      name: 'name',
      required: true,
      disabled: ({ formik }) => isDisabledFee(formik),
      schema: yup
        .string()
        .max(
          AppConstants.FEE_NAME_LIMIT,
          `Fee name must have ${AppConstants.FEE_NAME_LIMIT} characters or less.`,
        )
        .required('Fee name is required'),
    },
    {
      label: 'Fee Category',
      placeholder: 'Select fee category',
      type: EFormFieldType.OPTIONS,
      name: 'feeCategory',
      multiple: false,
      required: true,
      schema: yup.string().required('Fee category is required'),
      disabled: ({ formik }) => isDisabledFee(formik),
      options: ({ formik }) =>
        Object.keys(FeeCategory)
          .map((key) => ({
            label: capitalizeFirstLetters(feeCategoryMap[key.toLowerCase()]),
            value: FeeCategory[key],
          }))
          .filter((value) => {
            if (isEditing) {
              if (formik.values.feeCategory === FeeCategory.MANUAL && !hasManualFeesOption) {
                return value.label == 'Manual'
              }
              if (formik.values.feeCategory === FeeCategory.SURCHARGE && !hasSurchargeFeesOption) {
                return value.label == 'Shop Surcharge'
              }
            }
            if (hasSurchargeFeesOption && !hasManualFeesOption)
              return value.label == 'Shop Surcharge'
            if (!hasSurchargeFeesOption && hasManualFeesOption) return value.label == 'Manual'
            return value
          }),
    },
    {
      label: 'Fee Amount',
      placeholder: 'Insert fee amount',
      disabled: ({ formik }) => isDisabledFee(formik),
      tooltip: ({ formik }) => (!formik.values.feeType ? 'Select fee type' : ''),
      type: ({ formik }) =>
        formik?.values.feeType === FeeType.Percentage
          ? EFormFieldType.PERCENT
          : EFormFieldType.CURRENCY,
      name: 'amount',
      required: true,
      schema: yup
        .number()
        .default(null)
        .test({
          message: 'Must be an integer when',
          test: function (value) {
            const feeType = this.parent.feeType
            return feeType === FeeType.Percentage || (isNumber(value) && Number.isInteger(value))
          },
        })
        .test({
          message: 'Must be between 0 and 100 when',
          test: function (value) {
            const feeType = this.parent.feeType
            return feeType === FeeType.Flat || (isNumber(value) && value >= 0 && value <= 100)
          },
        })
        .required('Fee amount is required'),
      dimensions: { xs: 6, md: 6, lg: 6 },
    },
    {
      label: '',
      placeholder: 'Select fee type',
      type: EFormFieldType.OPTIONS,
      name: 'feeType',
      multiple: false,
      disabled: ({ formik }) => isDisabledFee(formik),
      schema: yup
        .string()
        .default(null)
        .test({
          message: 'using $',
          test: function (value) {
            const amount = this.parent.amount
            return value === FeeType.Percentage || (isNumber(amount) && Number.isInteger(amount))
          },
        })
        .test({
          message: 'using %',
          test: function (value) {
            const amount = this.parent.amount
            return value === FeeType.Flat || (isNumber(amount) && amount >= 0 && amount <= 100)
          },
        })
        .required('Fee type is required'),
      options: ({ formik }) =>
        Object.keys(FeeType)
          .map((key) => ({ label: feeTypeMap[key], value: FeeType[key] }))
          .filter((value) => {
            if (isDisabledFee(formik)) return value

            if (
              formik.values.feeCategory === FeeCategory.SURCHARGE &&
              (!isShopFlatSurchargeFeesEnabled || !isShopPercentageSurchargeFeesEnabled)
            ) {
              if (isShopFlatSurchargeFeesEnabled) {
                return value.label == '$'
              } else if (isShopPercentageSurchargeFeesEnabled) {
                return value.label == '%'
              }
            }
            if (
              formik.values.feeCategory === FeeCategory.MANUAL &&
              (!isShopFlatManualFeesEnabled || !isShopPercentageManualFeesEnabled)
            ) {
              if (isShopFlatManualFeesEnabled) {
                return value.label == '$'
              } else if (isShopPercentageManualFeesEnabled) {
                return value.label == '%'
              }
            }
            return value
          }),
      dimensions: { xs: 6, md: 6, lg: 6 },
      customStyles: {
        maxWidth: '50px',
        paddingLeft: '0px',
        paddingBottom: '0px',
        alignContent: 'end',
      },
    },
  ]
}
