import { atomFamily } from 'recoil'
import { ITerminal, ITerminalMeta, TAnyError } from '@/models'

export interface IPaymentServiceTerminalsState {
  loading: boolean
  error?: TAnyError | null
  data: ITerminal[]
  meta?: ITerminalMeta
}

export const paymentServicesTerminalsState = atomFamily<IPaymentServiceTerminalsState, string>({
  key: 'PaymentServiceTerminals',
  default: {
    loading: false,
    error: null,
    data: [],
  },
})
