import { useRecoilState } from 'recoil'
import { paymentOptionState } from '@/state/paymentOptionDetail'
import { IPaymentOption } from '@/models'
import { useEffect } from 'react'
import { useAppConfig } from '@/hooks/useAppConfig'

export function useAdminPaymentOptionUpsert(provider?: string | null) {
  const [pOptionState, setPaymentOptionState] = useRecoilState(paymentOptionState)

  const { config } = useAppConfig()
  const AdminPaymentOptions = config.factories.adminPaymentOptions

  const upsert = async (paymentOption: IPaymentOption) => {
    setPaymentOptionState({ ...pOptionState, error: null, loading: true })

    try {
      const result = await AdminPaymentOptions().upsert(paymentOption)
      setPaymentOptionState({
        data: result,
        error: null,
        loading: false,
      })
      return { data: result }
    } catch (e) {
      setPaymentOptionState({ ...pOptionState, error: e, loading: false })
      return { error: e }
    }
  }

  const fetchPaymentOption = async (provider: string) => {
    setPaymentOptionState({ ...pOptionState, error: null, loading: true })

    try {
      const result = await AdminPaymentOptions().fetchByProvider(provider)
      setPaymentOptionState({
        data: result,
        error: null,
        loading: false,
      })
      return { data: result }
    } catch (e) {
      setPaymentOptionState({ ...pOptionState, error: e, loading: false })
      return { error: e }
    }
  }

  useEffect(() => {
    if (provider) {
      void fetchPaymentOption(provider)
    }
  }, [provider])

  return {
    state: pOptionState,
    actions: {
      upsert,
    },
  }
}
