import { Route, Routes, useLocation } from 'react-router-dom'
import * as TestModels from '@/models/TestModels'
import { PaymentPage } from '@/pages/PaymentPage'
import { routes } from '@/helpers/routes'
import { PaymentServiceSettingsPage } from '@/pages/PaymentServiceSettingsPage'
import { ErrorPage } from '@/pages/ErrorPage'
import { PaymentServiceConnectPage } from '@/pages/PaymentServiceConnectPage'
import { TerminalConfigurationPage } from '@/pages/TerminalConfigurationPage'
import { FeeConfigurationPage } from '@/pages/FeeConfigurationPage'

export function CustomerAppRoutes() {
  const location = useLocation()
  const previousLocation = location.state?.previousLocation
  const fee = location.state?.fee

  return (
    <div data-testid={TestModels.ETestIdEnums.PAYMENT_PAGE_ROOT}>
      <Routes location={previousLocation || location}>
        <Route index element={<PaymentPage />} />
        <Route
          path={routes.paymentServiceDetail(':provider')}
          element={<PaymentServiceSettingsPage />}
        />

        <Route path={routes.error()} element={<ErrorPage />} />

        <Route path="*" element={<PaymentPage />} />
      </Routes>

      {/* for modals */}
      <Routes>
        {/* To avoid "no routes matching /" warning */}
        <Route index element={null} />
        <Route
          path={routes.connectPaymentService(':provider')}
          element={<PaymentServiceConnectPage />}
        />
        <Route
          path={routes.editPaymentService(':provider')}
          element={<PaymentServiceConnectPage />}
        />
        <Route path={routes.newTerminal(':provider')} element={<TerminalConfigurationPage />} />
        <Route path={routes.newFee(':provider')} element={<FeeConfigurationPage fee={fee} />} />
        <Route path={routes.editFee(':provider')} element={<FeeConfigurationPage fee={fee} />} />
      </Routes>
    </div>
  )
}
