import { EPaymentConfigurationStatus } from '@/models'
import { CheckCircle, AccessTime, Unpublished } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { makeStyles } from '@/theme'

interface IPaymentServiceApprovalStatusBadgeProps {
  status: EPaymentConfigurationStatus
}

export function PaymentServiceApprovalStatusBadge(props: IPaymentServiceApprovalStatusBadgeProps) {
  return (
    <Tooltip title={StatusLabels[props.status]} arrow>
      {getIcon(props.status)}
    </Tooltip>
  )
}

function getIcon(status: EPaymentConfigurationStatus) {
  const { cx, classes } = useStyles()

  switch (status) {
    case EPaymentConfigurationStatus.DENIED:
      return <Unpublished className={cx(classes.denied)} />
    case EPaymentConfigurationStatus.PENDING_APPROVAL:
      return <AccessTime />
    case EPaymentConfigurationStatus.APPROVED:
      return <CheckCircle className={cx(classes.approved)} />
    default:
      return <></>
  }
}

const useStyles = makeStyles()((theme) => ({
  denied: {
    color: theme.palette.error.main,
  },
  approved: {
    color: theme.palette.success.main,
  },
}))

const StatusLabels = {
  [EPaymentConfigurationStatus.DENIED]: 'Denied',
  [EPaymentConfigurationStatus.PENDING_APPROVAL]: 'Pending approval',
  [EPaymentConfigurationStatus.APPROVED]: 'Approved',
}
