import { useEffect } from 'react'
import { useAdminPaymentOptions } from '@/hooks/useAdminPaymentOptions'
import { IProvider } from '@/models'
import { usePaymentProviders } from '@/hooks/usePaymentProviders'

export function useAvailablePaymentProvidersForCreation(): {
  providers: IProvider[]
  canCreate: boolean
} {
  const { state: paymentOptionsState, actions: paymentOptionsActions } = useAdminPaymentOptions()

  const { providers } = usePaymentProviders()

  useEffect(() => {
    if (!paymentOptionsState.data?.length) {
      void paymentOptionsActions.fetchAll()
    }
  }, [])

  const allowedProviders = providers?.filter(
    (provider) =>
      !paymentOptionsState.data?.find((paymentOption) => paymentOption.provider === provider.value),
  )
  return {
    providers: allowedProviders,
    canCreate: allowedProviders?.length !== 0,
  }
}
