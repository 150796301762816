import { Input } from '@/components/Input'
import { NumericFormat } from 'react-number-format'
import { toCents, toDollars } from '@/helpers/currency'

interface ICurrencyInputProps {
  disabled?: boolean
  allowNullable?: boolean
  value?: number | null
  onChange: (value: number | null) => void
  withPrefix?: boolean
}

export function CurrencyInput({
  disabled,
  allowNullable,
  value,
  onChange,
  withPrefix = true,
}: ICurrencyInputProps) {
  return (
    <NumericFormat
      disabled={disabled}
      thousandSeparator={','}
      decimalSeparator={'.'}
      allowNegative={false}
      allowLeadingZeros={false}
      prefix={withPrefix ? '$' : ''}
      decimalScale={2}
      customInput={Input}
      onValueChange={(values) => {
        allowNullable && !values.value ? onChange(null) : onChange(toCents(values.value))
      }}
      value={typeof value === 'number' ? toDollars(value) : null}
    />
  )
}
