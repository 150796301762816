import LedgerGreenLogo from './assets/ledger_green_logo.png'
import AeropayLogo from './assets/aeropay_logo.svg'
import BlazePayLogo from './assets/blazepay_logo.svg'
import SpenceLogo from './assets/spence_logo.svg'
import StrongholdLogo from './assets/stronghold_logo.svg'
import CashlessATMLogo from './assets/cashless_atm_logo.svg'
import MerrcoLogo from './assets/merrco_logo.svg'
import CashLogo from './assets/cash_logo.svg'
import CheckLogo from './assets/check_logo.svg'
import StoreCreditLogo from './assets/store_credit_logo.svg'
import ACHLogo from './assets/ach_logo.svg'
import BirchmountLogo from './assets/birchmount_logo.svg'

export const logos = {
  ledgergreen: LedgerGreenLogo,
  aeropay: AeropayLogo,
  blazepay_debit: BlazePayLogo,
  blazepay_credit: BlazePayLogo,
  spence: SpenceLogo,
  stronghold: StrongholdLogo,
  cashless_atm: CashlessATMLogo,
  merrco: MerrcoLogo,
  cash: CashLogo,
  check: CheckLogo,
  store_credit: StoreCreditLogo,
  ach: ACHLogo,
  swifter: ACHLogo,
  birchmount: BirchmountLogo,
}
