import Axios from 'axios'
import { IAppConfig } from '@/config/types'
import { EventConstants } from '@/constants'

export const createHttpClient = (config: IAppConfig) =>
  withInterceptors(
    Axios.create({
      baseURL: config.baseURL,
      headers: {
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
    }),
    config,
  )

const withInterceptors = (axiosInstance, config: IAppConfig) => {
  axiosInstance.interceptors.request.use(
    async (axiosConfig) => {
      axiosConfig.headers['Authorization'] =
        typeof config.authToken === 'function' ? await config.authToken() : config.authToken

      return axiosConfig
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  axiosInstance.interceptors.response.use(
    (res) => res,
    (err) => {
      const statusCode = err.response?.status
      if (statusCode === 401 || statusCode === 403) {
        // Notify host application so that it can refresh the auth token
        const EventBus = config.factories.eventBus
        EventBus().publish(EventConstants.HTTP_ERROR, {
          statusCode,
        })

        config.router.navigate(`/error?status=${statusCode}`)
      }

      throw err
    },
  )

  return axiosInstance
}
