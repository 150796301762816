import { useEffect, useState } from 'react'
import { useToast } from '@/hooks/useToast'
import { TAnyError } from '@/models'

interface IWarningToastsProps<T> {
  successMessage: string
  defaultErrorMessage: string
  loading: boolean
  error?: TAnyError | null
  data: T
}

export function useWarningToasts<T>(props: IWarningToastsProps<T>) {
  const toast = useToast()

  const [notificationsEnabled, setNotificationsEnabled] = useState<boolean>(false)
  useEffect(() => {
    if (!props.error || !notificationsEnabled) return

    toast.showMessage(props.error?.message ?? props.defaultErrorMessage, 'error')
  }, [props.error])

  useEffect(() => {
    if (!notificationsEnabled || props.loading || props.error) return

    toast.showMessage(props.successMessage, 'success')
  }, [props.data])

  useEffect(() => {
    setNotificationsEnabled(true)
  }, [])
}
