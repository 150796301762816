import * as React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'

interface IConfirmationDialogProps {
  title: string
  text?: string
  open: boolean
  loading: boolean
  onClose: () => void
  onCancel: () => void
  onConfirm: () => void
}
export function ConfirmationDialog(props: IConfirmationDialogProps) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      {props.text && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{props.text}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={props.onCancel}>Cancel</Button>
        <LoadingButton variant={'contained'} loading={props.loading} onClick={props.onConfirm}>
          Ok
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
