import { Grid, Tooltip } from '@mui/material'
import { Input } from '@/components/Input'
import { TextArea } from '@/components/TextArea'
import { FormField } from './FormField'
import { TFormFieldOrFormFieldList } from '@/helpers/formBuilder'
import { EFormFieldType, IFormField, TBasicType } from '@/models'
import { NumberPicker } from '@/components/NumberPicker'
import { FocusEventHandler } from 'react'
import { FormikProps } from 'formik'
import { Select } from '@/components/Select'
import { FileUpload } from '@/components/FileUpload'
import { Switch } from '@/components/Switch'
import { AutoComplete } from '@/components/AutoComplete'
import { CurrencyInput } from '@/components/CurrencyInput'
import { PercentInput } from '@/components/PercentInput'
import { RichTextInput } from '@/components/RichTextInput'
import get from 'lodash/get'

export type TFormik<T> = FormikProps<T>

export function FormFields<T>(props: { fields: TFormFieldOrFormFieldList[]; formik: TFormik<T> }) {
  const { fields, formik } = props

  const formContent = fields
    .filter((field) => !isHiddenField(field, formik))
    .map((fieldOrList, index) => {
      if (Array.isArray(fieldOrList)) {
        const rowContent = fieldOrList
          .filter((field) => !isHiddenField(field, formik))
          .map((field, index2) => (
            <Grid item key={`${field.name}-${index2}`} {...(field.dimensions || {})}>
              {field.customFieldComponent ? (
                field.customFieldComponent({ field, formik })
              ) : (
                <Field field={field} formik={props.formik} />
              )}
            </Grid>
          ))
        return (
          <Grid container item justifyContent="flex-start" style={{ gap: '16px' }} key={index}>
            {rowContent}
          </Grid>
        )
      } else {
        return (
          <Grid
            container
            item
            key={fieldOrList.name}
            style={fieldOrList.customStyles || {}}
            {...(fieldOrList.dimensions || {})}
          >
            {fieldOrList.customFieldComponent ? (
              fieldOrList.customFieldComponent({ field: fieldOrList, formik })
            ) : (
              <Field field={fieldOrList} formik={props.formik} />
            )}
          </Grid>
        )
      }
    })
  return (
    <Grid container spacing={6}>
      {formContent}
    </Grid>
  )
}

function Field<T>(props: { formik: TFormik<T>; field: IFormField }) {
  const { formik, field } = props
  const error = isTouched(formik, field.name) && Boolean(getErrors(formik, field.name))

  const tooltip = typeof field.tooltip === 'function' ? field.tooltip({ formik }) : field.tooltip

  const fieldType =
    typeof field.type === 'function' ? field.type({ formik: props.formik }) : field.type

  return (
    <Tooltip title={tooltip} arrow>
      <div style={{ width: '100%' }}>
        <FormField
          label={field.label + (field.required ? '*' : '')}
          description={field.description}
          dangerNote={field.dangerNote && field.dangerNote({ formik })}
          fieldType={fieldType}
          error={error}
          helperText={isTouched(formik, field.name) && getErrors(formik, field.name)}
          component={
            <FieldSwitch
              field={field}
              formik={formik}
              allowNullable={field.allowNullable}
              value={getValue(formik, field.name)}
              onChange={(value: TBasicType) => {
                void formik.setFieldValue(field.name, value)
              }}
              onBlur={formik.handleBlur}
              error={error}
            />
          }
        />
      </div>
    </Tooltip>
  )
}

interface IFieldSwitchProps<T> {
  value: TBasicType | FileList | null
  onChange: (v: TBasicType | TBasicType[] | FileList | null) => void
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  error?: boolean
  disabled?: boolean
  allowNullable?: boolean
  formik?: TFormik<T>
  field: IFormField
}

export function FieldSwitch<T>(props: IFieldSwitchProps<T>) {
  const { field, formik, ...otherProps } = props
  const fieldProps = {
    placeholder: field.placeholder,
    id: field.name,
    name: field.name,
    ...otherProps,
  }
  const fieldType =
    typeof field.type === 'function' ? field.type({ formik: props.formik }) : field.type

  const disabled =
    (typeof field.disabled === 'function'
      ? field.disabled({ formik: props.formik })
      : field.disabled) || props.disabled

  const options = typeof field.options === 'function' ? field.options({ formik }) : field.options

  switch (fieldType) {
    case EFormFieldType.BOOLEAN:
      return (
        <Switch
          disabled={disabled}
          onChange={(evt, active) => fieldProps.onChange(active)}
          checked={!!fieldProps.value}
        />
      )
    case EFormFieldType.NUMBER:
      return (
        <NumberPicker
          {...fieldProps}
          disabled={disabled}
          value={Number(fieldProps.value)}
          min={props.field.min}
          max={props.field.max}
        />
      )
    case EFormFieldType.OPTIONS:
      return (
        <Select
          disabled={disabled}
          error={fieldProps.error}
          value={fieldProps.value || []}
          placeholder={fieldProps.placeholder}
          multiple={field.multiple}
          onChange={fieldProps.onChange}
          options={options}
          showPlaceholderInMenu={field.showPlaceholderInMenu}
        />
      )
    case EFormFieldType.AUTOCOMPLETE:
      return (
        <AutoComplete
          disabled={disabled}
          options={options || []}
          placeholder={fieldProps.placeholder}
          onChange={fieldProps.onChange}
          error={fieldProps.error}
          renderOption={field.renderOption}
        />
      )
    case EFormFieldType.BIG_STRING:
      return (
        <TextArea
          {...fieldProps}
          disabled={disabled}
          minRows={field.minRows}
          value={(fieldProps.value || '') as string}
          onChange={(evt) => fieldProps.onChange(evt.target.value)}
        />
      )
    case EFormFieldType.RICH_TEXT:
      return (
        <RichTextInput
          placeholder={fieldProps.placeholder}
          disabled={disabled}
          error={fieldProps.error}
          value={(fieldProps.value || '') as string}
          onChange={(value) => fieldProps.onChange(value)}
        />
      )
    case EFormFieldType.FILE_UPLOAD:
      return (
        <FileUpload
          disabled={disabled}
          value={fieldProps.value as FileList}
          initialValue={field.initialValue as string}
          onChange={fieldProps.onChange}
        />
      )
    case EFormFieldType.CURRENCY:
      return (
        <CurrencyInput
          disabled={disabled}
          allowNullable={fieldProps.allowNullable}
          onChange={fieldProps.onChange}
          value={fieldProps.value as number}
          withPrefix={false}
        />
      )
    case EFormFieldType.PERCENT:
      return (
        <PercentInput
          disabled={disabled}
          onChange={fieldProps.onChange}
          value={fieldProps.value as number}
          withSuffix={false}
        />
      )
    case EFormFieldType.HIDDEN:
    case EFormFieldType.STRING:
    default:
      return (
        <Input
          disabled={disabled}
          {...fieldProps}
          onChange={(evt) => fieldProps.onChange(evt.target.value)}
        />
      )
  }
}

function isHiddenField<T>(field: TFormFieldOrFormFieldList, formik: TFormik<T>) {
  if (Array.isArray(field)) return false

  return typeof field.type === 'function'
    ? field.type({ formik }) === EFormFieldType.HIDDEN
    : field.type === EFormFieldType.HIDDEN
}

const isTouched = (formik, fieldName) => get(formik.touched, fieldName)

const getErrors = (formik, fieldName) => get(formik.errors, fieldName)

const getValue = (formik, fieldName) => get(formik.values, fieldName)
