import {
  AccordionDetails,
  Accordion as MuiAccordion,
  AccordionSummary,
  Typography,
  styled,
  BoxProps,
} from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'

import { useEffect, useState } from 'react'
import { makeStyles } from '@/theme'

const StyledAccordion = styled(MuiAccordion)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: `0`,
  padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  boxSizing: 'border-box',
  boxShadow: 'none',

  '&::before': {
    backgroundColor: theme.palette.border['100'],
  },
}))

export function Accordion({ title, content }) {
  const [expanded, setExpanded] = useState(false)
  const { classes, cx } = useStyles()

  const [expandedOnce, setExpandedOnce] = useState(false) // This helps: 1. not rendering before expanding, 2. not losing the component state after collapsing it

  useEffect(() => {
    if (expanded) setExpandedOnce(true)
  }, [expanded])

  return (
    <StyledAccordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary className={cx(classes.summary)} aria-controls="panel1a-content">
        {expanded ? <KeyboardArrowDown /> : <KeyboardArrowRightIcon />}
        <Typography>{title}</Typography>
      </AccordionSummary>
      {expandedOnce && <AccordionDetails>{content}</AccordionDetails>}
    </StyledAccordion>
  )
}

const useStyles = makeStyles()(() => ({
  summary: {
    height: '45px',
  },
}))
