import { Input } from '@/components/Input'
import { NumberFormatValues, NumericFormat } from 'react-number-format'

interface IPercentageInputProps {
  disabled?: boolean
  value?: number | null
  onChange: (value: number | null) => void
  decimalScale?: number
  max?: number
  withSuffix?: boolean
}

export function PercentInput({
  disabled,
  value,
  onChange,
  decimalScale = 2,
  max,
  withSuffix = true,
}: IPercentageInputProps) {
  const handleOnChange = (values: NumberFormatValues) => {
    const floatValue = values.floatValue ?? null
    const adjustedValue = floatValue && max && floatValue > max ? max : floatValue
    onChange(adjustedValue)
  }

  const isAllowed = (values: NumberFormatValues) => {
    const { floatValue } = values
    if (!floatValue) return true
    if (!max) return true
    return floatValue <= max
  }

  return (
    <NumericFormat
      disabled={disabled}
      thousandSeparator={','}
      decimalSeparator={'.'}
      allowNegative={false}
      allowLeadingZeros={false}
      suffix={withSuffix ? '%' : ''}
      decimalScale={decimalScale}
      customInput={Input}
      onValueChange={handleOnChange}
      value={typeof value === 'number' ? value : null}
      isAllowed={isAllowed}
    />
  )
}
