import { IExternalPos, IExternalTerminal, ITerminalOption } from '@/models'
import { useTerminalOptions } from '@/hooks/useTerminalOptions'
import { usePaymentServiceTerminals } from '@/hooks/usePaymentServiceTerminals'

export function useAvailableTerminalOptionsForCreation(provider?: string): {
  terminalOptions: ITerminalOption[]
  canCreate
  availableExternalTerminals?: IExternalTerminal[]
  availableExternalPos?: IExternalPos[]
} {
  const { state } = usePaymentServiceTerminals(provider || '')
  const { terminalOptions } = useTerminalOptions(provider)

  const availableTerminalOptions = terminalOptions?.filter(
    (option) => !state.data.find((terminal) => terminal.terminalId === option.terminalId),
  )

  const availableExternalTerminals = state?.meta?.availableExternalTerminals
  const availableExternalPos = state?.meta?.availableExternalPos

  return {
    terminalOptions: availableTerminalOptions,
    canCreate: availableTerminalOptions?.length > 0,
    availableExternalTerminals,
    availableExternalPos,
  }
}
