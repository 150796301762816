import { ConfirmationDialog } from '@/components/ConfirmationDialog'
import { ReactNode, RefObject, useRef, useState } from 'react'
import { TFormik } from '@/components/forms/Form/FormFields'
import { IPaymentServiceConfiguration } from '@/models'

interface IWithDiscardChangesConfirmationDialogProps {
  onConfirm: () => void
  children: (params: {
    formRef: RefObject<TFormik<unknown> | null>
    requestConfirmation: () => void
  }) => ReactNode
}
export function WithDiscardChangesConfirmationDialog(
  props: IWithDiscardChangesConfirmationDialogProps,
) {
  const formRef = useRef<TFormik<Partial<IPaymentServiceConfiguration>> | null>(null)
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const closeConfirmation = () => setConfirmationOpen(false)
  const onConfirm = () => {
    closeConfirmation()
    props.onConfirm()
  }
  const requestConfirmation = () => {
    if (formRef.current?.dirty) {
      setConfirmationOpen(formRef.current.dirty)
    } else {
      onConfirm()
    }
  }

  return (
    <>
      <ConfirmationDialog
        title={'Discard Changes?'}
        text={'Any unsaved configuration changes will be lost'}
        open={confirmationOpen}
        loading={false}
        onClose={closeConfirmation}
        onCancel={closeConfirmation}
        onConfirm={onConfirm}
      />
      {props.children({ formRef, requestConfirmation })}
    </>
  )
}
