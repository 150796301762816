import * as yup from 'yup'
import { EFormFieldType, TAppEnv, TPaymentOptionDefaults, TPaymentService } from '@/models'
import { IAppConfig } from '@/config/types'

export const meta = ({ platform }): Record<string, TPaymentOptionDefaults> => ({
  tfi: {
    terminalsSetup: {
      disabled: () => false,
      fields: ['terminalId', 'externalTerminalId', 'externalPosId'],
    },
    supportFees: true,
    fields: [
      {
        label: 'Client ID',
        name: 'clientId',
        schema: yup.string().required('Client ID is required'),
        type: EFormFieldType.STRING,
        required: true,
        placeholder: 'Insert client id',
      },
      {
        label: 'Client Secret',
        name: 'clientSecret',
        schema: yup.string(),
        type: EFormFieldType.STRING,
        required: false,
        hideOnConnect: true,
        placeholder: 'Insert client secret',
      },
      {
        label: 'Public Key',
        name: 'publicKey',
        schema: yup.string(),
        type: EFormFieldType.STRING,
        required: false,
        hideOnConnect: true,
        placeholder: 'Insert public key',
      },
      {
        label: 'Webhook URL',
        name: 'webhookUrl',
        type: EFormFieldType.STRING,
        readOnly: true,
      },
    ],
    settings: {},
  },
  ledgergreen: {
    fields: [
      {
        label: 'Client Secret',
        name: 'clientSecret',
        schema: yup.string().required('Client secret is required'),
        type: EFormFieldType.STRING,
        required: true,
        hideOnConnect: true,
        placeholder: 'Insert client secret',
      },
      {
        label: 'Public Key',
        name: 'publicKey',
        schema: yup.string().required('Public key is required'),
        type: EFormFieldType.STRING,
        required: true,
        hideOnConnect: true,
        placeholder: 'Insert public key',
      },
      {
        label: 'Webhook URL',
        name: 'webhookUrl',
        type: EFormFieldType.STRING,
        readOnly: true,
      },
    ],
    settings: {},
  },
  swifter: {
    fields: [
      {
        label: 'Client Secret',
        name: 'clientSecret',
        schema: yup.string().required('Client secret is required'),
        type: EFormFieldType.STRING,
        required: true,
        hideOnConnect: true,
        placeholder: 'Insert client secret',
      },
      {
        label: 'Client ID',
        name: 'clientId',
        schema: yup.string().required('Client ID is required'),
        type: EFormFieldType.STRING,
        required: true,
        placeholder: 'Insert client id',
      },
      {
        label: 'Webhook URL',
        name: 'webhookUrl',
        type: EFormFieldType.STRING,
        readOnly: true,
      },
    ],
    settings: {
      deferCapture: {
        title: 'Deferred Capture Payment',
        description: 'When turned on, charges are only captured on order completion',
        type: EFormFieldType.BOOLEAN,
      },
    },
  },
  adyen: {
    terminalsSetup: {
      disabled: (paymentService) =>
        paymentService.clientId ? false : 'Must finish onboarding to enable Terminal Setup tab.',
      fields: ['terminalId', 'externalTerminalId'],
    },
    supportAllowedOrigins: true,
    supportFees: true,
    tips:
      platform === 'pos'
        ? {
            supportCustomTips: true,
            supportPredefinedTips: true,
          }
        : null,
    fields: [
      {
        label: 'Email',
        name: 'extraContent.email',
        schema: yup.string().email('Invalid email').required('Email is required'),
        type: EFormFieldType.STRING,
        disabled: ({ formik }) =>
          !!(formik?.initialValues as TPaymentService).extraContent?.email &&
          !!(formik?.initialValues as TPaymentService).status,
        required: true,
        placeholder: 'Insert email',
      },
      {
        label: 'Organization Name',
        name: 'extraContent.organizationName',
        schema: yup.string().required('Organization name is required'),
        type: EFormFieldType.STRING,
        disabled: ({ formik }) =>
          !!(formik?.initialValues as TPaymentService).extraContent?.organizationName,
        required: true,
        placeholder: 'Insert organization name',
      },
      {
        label: 'Store Name',
        name: 'extraContent.storeName',
        schema: yup.string().required('Store name is required'),
        type: ({ formik }) => {
          if ((formik?.initialValues as TPaymentService).extraContent?.storeName) {
            return EFormFieldType.HIDDEN
          }

          return EFormFieldType.STRING
        },
        required: true,
        placeholder: 'Insert store name',
      },
      {
        label: 'System type',
        name: 'extraContent.systemType',
        schema: yup.string().required(),
        type: EFormFieldType.HIDDEN,
        writeOnly: true, // used to hide in the configuration details
        required: true,
      },
      {
        label: 'Onboarding URL',
        name: 'extraContent.redirectUrl',
        type: EFormFieldType.STRING,
        readOnly: true,
      },
      {
        label: 'POS store ID',
        name: 'clientId',
        type: EFormFieldType.STRING,
        platform: 'pos',
        readOnly: true,
      },
      {
        label: 'ECOM store ID',
        name: 'extraClientId',
        type: EFormFieldType.STRING,
        platform: 'ecom',
        readOnly: true,
      },
      {
        label: 'Client ID (Widget)',
        name: 'widgetClientId',
        type: EFormFieldType.STRING,
        readOnly: true,
      },
    ],
    externalLinks: (config: IAppConfig) => {
      const merchantPortals = {
        [TAppEnv.PRODUCTION]: 'https://pay.getgreenline.co/',
        [TAppEnv.STAGING]: 'https://payments.blazeme.site/',
        [TAppEnv.SANDBOX]: 'https://payments.blazeme.site/',
      }
      const merchantPortalUrl = merchantPortals[config.getEnvironment()]

      if (merchantPortalUrl) return [{ label: 'Go to merchant portal', url: merchantPortalUrl }]

      return []
    },
    settings: {
      copyCredentials: {
        title: 'Use my KYC for applications for E-commerce and POS',
        description:
          'Enable to ensure all funds go to the same merchant account. If disabled, you will be required to submit a second application.',
        type: EFormFieldType.BOOLEAN,
      },
    },
  },
  aeropay: {
    supportFees: true,
    fields: [
      {
        label: 'Merchant ID',
        name: 'clientSecret',
        schema: yup.string().required('Merchant ID is required'),
        type: EFormFieldType.STRING,
        required: true,
        placeholder: 'Insert Merchant ID',
      },
      {
        label: 'Merchant Location ID',
        name: 'extraClientId',
        schema: yup.string().required('Merchant location ID is required'),
        type: EFormFieldType.STRING,
        required: true,
        placeholder: 'Insert merchant location ID',
      },
      {
        label: 'Merchant Location UUID',
        name: 'clientId',
        schema: yup.string().required('Merchant location UUID is required'),
        type: EFormFieldType.STRING,
        required: true,
        placeholder: 'Insert merchant location UUID',
      },
    ],
    settings: {
      deferCapture: {
        title: 'Deferred Capture Payment',
        description: 'When turned on, charges are only captured on order completion',
        type: EFormFieldType.BOOLEAN,
        bypassExtraContentOnUpdate: true,
      },
      allowPrePaymentSms: {
        title: 'Allow SMS for Pre-Payment',
        description:
          'Automatically send an SMS to customers for pre-payment when an incoming order is accepted, excluding BLAZE ECOM orders.',
        type: platform === 'pos' ? EFormFieldType.BOOLEAN : EFormFieldType.HIDDEN,
        bypassExtraContentOnUpdate: true,
      },
    },
  },
  payauth: {
    terminalsSetup: {
      disabled: () => false,
      fields: ['terminalId', 'externalTerminalId', 'externalPosId', 'externalPosKey'],
    },
    supportFees: true,
    tips:
      platform === 'pos'
        ? {
            supportCustomTips: false,
            supportPredefinedTips: false,
          }
        : null,
    fields: [
      {
        label: 'Client Secret',
        name: 'clientSecret',
        schema: yup.string(),
        type: EFormFieldType.STRING,
        required: true,
        placeholder: 'Insert client secret',
      },
    ],
    settings: {},
  },
  birchmount: {
    fields: [
      {
        label: 'Client Secret',
        name: 'clientSecret',
        schema: yup.string().required('Client secret is required'),
        type: EFormFieldType.STRING,
        required: true,
        placeholder: 'Insert client secret',
      },
    ],
    settings: {},
  },
  greenbax: {
    supportFees: true,
    fields: [
      {
        label: 'Merchant Token',
        name: 'clientId',
        schema: yup.string().required('Merchant token is required'),
        type: EFormFieldType.STRING,
        required: true,
        placeholder: 'Insert merchant token',
      },
      {
        label: 'Location Token',
        name: 'extraClientId',
        schema: yup.string().required('Location token is required'),
        type: EFormFieldType.STRING,
        required: true,
        placeholder: 'Insert location token',
      },
    ],
    settings: {
      allowPrePaymentSms: {
        title: 'Allow SMS for Pre-Payment',
        description:
          'Automatically send an SMS to customers for pre-payment when an incoming order is accepted, excluding BLAZE ECOM orders.',
        type: platform === 'pos' ? EFormFieldType.BOOLEAN : EFormFieldType.HIDDEN,
        bypassExtraContentOnUpdate: true,
      },
    },
  },
})
