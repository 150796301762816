import { Box } from '@/components/Box'
import { Tab, Tabs as MuiTabs, Tooltip } from '@mui/material'
import { makeStyles } from '@/theme'
import { ReactElement, SyntheticEvent, useState } from 'react'
import { TabContext, TabPanel } from '@mui/lab'
import { Divider } from '@/components/Divider'

interface ITab {
  title: string
  content: ReactElement
  disabled?: string | false
}
interface ITabsProps {
  tabs: ITab[]
}

export function Tabs(props: ITabsProps) {
  const { tabs } = props
  const [value, setValue] = useState(tabs[0]?.title || '')

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const { classes, cx } = useStyles()

  return (
    <TabContext value={value}>
      <Box className={cx(classes.root)}>
        <MuiTabs value={value} onChange={handleChange} className={cx(classes.tabs)}>
          {tabs.map((tab, index) => {
            const content = (
              <Tab
                key={index}
                className={cx(classes.tabButton)}
                value={tab.title}
                label={tab.title}
                disabled={!!tab.disabled}
              />
            )

            if (!tab.disabled) return content

            return (
              <Tooltip key={index} title={tab.disabled} arrow>
                {content}
              </Tooltip>
            )
          })}
        </MuiTabs>

        <Divider />

        {tabs.map((tab, index) => (
          <TabPanel key={index} value={tab.title} sx={{ padding: '24px' }}>
            {tab.content}
          </TabPanel>
        ))}
      </Box>
    </TabContext>
  )
}

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: 0,
  },
  tabs: {
    '& .MuiTabs-flexContainer': {
      columnGap: '16px',
      padding: '0 24px',
      height: '75px',
    },
  },
  tabButton: {
    padding: 0,
    minWidth: 0,
    color: `${theme.palette.text.primary} !important`,

    '&.Mui-disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
      pointerEvents: 'all',
    },
  },
}))
