import { createTheme } from '@mui/material'
import { useTheme } from '@mui/material/styles'

export const lightTheme = createTheme({
  typography: {
    h2: {
      fontWeight: 500,
      fontSize: '20px',
    },
    subtitle1: {
      fontWeight: 500,
    },
    subtitle2: {
      fontWeight: 400,
    },
  },
  spacing: 4,
  palette: {
    text: {
      primary: '#3E3F42',
      secondary: '#9EA0A5',
    },
    primary: {
      main: '#2CB2DC',
      contrastText: '#ffffff',
    },
    border: {
      '100': '#EEEEEE',
      '200': '#E2E5ED',
    },
  },
  decoration: {
    shadow: {
      default: '0px 1px 2px 0px #66717B36 inset',
    },
    border: {
      radius: {
        default: 8,
        s: 4,
      },
    },
  },
})

export const darkTheme = createTheme({
  ...lightTheme,
  palette: {
    mode: 'dark',
    border: {
      '100': '#4B4B4B',
      '200': '#4B4B4B',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#272727',
        },
      },
    },
  },
})

import { createMakeAndWithStyles } from 'tss-react'

export const { makeStyles, withStyles } = createMakeAndWithStyles({ useTheme })

declare module '@mui/material/styles' {
  interface Palette {
    border: {
      '100': string | number
      '200': string | number
    }
  }
  interface PaletteOptions {
    border: {
      '100'?: string | number
      '200'?: string | number
    }
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    decoration: {
      shadow: {
        default: string
      }
      border: {
        radius: {
          default: string | number
          s: string | number
        }
      }
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    decoration?: {
      shadow?: {
        default: string
      }
      border?: {
        radius?: {
          default?: string | number
          s: string | number
        }
      }
    }
  }
}
