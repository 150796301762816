import { useRecoilValue, useSetRecoilState } from 'recoil'
import { appConfigState } from '@/state/appConfig'
import { bindFactories } from '@/config/utils'
import { AppConfig } from '@config'

export function useAppConfig() {
  let config = useRecoilValue(appConfigState)
  const setAppConfig = useSetRecoilState(appConfigState)

  if (!config.initialized) {
    config = bindFactories(new AppConfig())
    setAppConfig(config)
  }

  return { config }
}
