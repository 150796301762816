import { useNavigate, useParams } from 'react-router-dom'
import { FormModal } from '@/components/FormModal'
import { TerminalConfigurationForm } from '@/components/forms/TerminalConfigurationForm'
import {
  usePaymentServiceTerminal,
  usePaymentServiceTerminals,
} from '@/hooks/usePaymentServiceTerminals'
import { useAvailableTerminalOptionsForCreation } from '@/hooks/useAvailableTerminalOptionsForCreation'
import { usePaymentService } from '@/hooks/usePaymentService'
import { routes } from '@/helpers/routes'

export const TerminalConfigurationPage = () => {
  const { provider } = useParams()
  const { actions } = usePaymentServiceTerminal(provider)

  const { terminalOptions, availableExternalTerminals, availableExternalPos } =
    useAvailableTerminalOptionsForCreation(provider)

  const {
    actions: { fetchTerminals },
  } = usePaymentServiceTerminals(provider || '')

  const {
    state: { data: paymentService },
  } = usePaymentService(provider)

  const navigate = useNavigate()
  const closeModal = () => navigate(routes.paymentServiceDetail(provider))
  const onSuccess = () => {
    closeModal()
    void fetchTerminals()
  }

  return (
    <FormModal title={'Terminal Configuration'} onClose={closeModal}>
      <TerminalConfigurationForm
        terminalOptions={terminalOptions}
        availableExternalTerminals={availableExternalTerminals}
        availableExternalPos={availableExternalPos}
        enabledFields={paymentService?.terminalsSetup?.fields}
        onSubmit={actions.upsertTerminal}
        onSuccess={onSuccess}
        onCancel={closeModal}
      />
    </FormModal>
  )
}
