import { createHttpClient } from '@/helpers/httpClient'
import { deserialise } from 'kitsu-core'
import { Endpoints, RecoilConstants } from '@/constants'
import { IProvider } from '@/models'
import { selector, useRecoilValueLoadable } from 'recoil'
import { appConfigState } from '@/state/appConfig'

const paymentProviders = selector({
  key: 'paymentProviders',
  get: async ({ get }) => {
    const config = get(appConfigState)
    const httpClient = createHttpClient(config)
    const response = await httpClient.get(Endpoints.v1.providers())

    return deserialise(response.data).data
  },
})

export function usePaymentProviders(): { providers: IProvider[] } {
  const { state, contents } = useRecoilValueLoadable(paymentProviders)

  return {
    providers: state === RecoilConstants.HAS_VALUE ? contents : null,
  }
}
