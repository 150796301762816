import { makeStyles } from '@/theme'
import { TPaymentService } from '@/models'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { DeleteConfirmationButton } from '@/components/DeleteConfirmationButton'
import { usePaymentServiceAllowedOrigins } from '@/hooks/usePaymentServiceAllowedOrigins'
import { LoadingButton } from '@mui/lab'
import { Input } from '@/components/Input'
import { useState } from 'react'
import { useToast } from '@/hooks/useToast'
import { getErrorMessages } from '@/helpers/errors'

interface IAllowedOriginsSectionProps {
  paymentService: TPaymentService
}

export const AllowedOriginsSection = (props: IAllowedOriginsSectionProps) => {
  const { state, actions } = usePaymentServiceAllowedOrigins()
  const { classes, cx } = useStyles()
  const toast = useToast()
  const allowedOrigins = props.paymentService.allowedOrigins || []

  const [newOrigin, setNewOrigin] = useState<string>('')

  const addAllowedOrigin = async () => {
    const { error } = await actions.addAllowedOrigin(props.paymentService, newOrigin)
    if (error) {
      let msg = 'Failed to add origin'
      if (typeof error === 'string' || typeof error === 'object') {
        msg = getErrorMessages(error).join('. ')
      }
      toast.showMessage(msg, 'error')
      return
    }
    setNewOrigin('')
  }

  return (
    <div className={cx(classes.container)}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 600 }}>
          <TableHead>
            <TableRow>
              <TableCell>Origin</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {allowedOrigins.map((origin) => (
              <TableRow key={origin} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{origin}</TableCell>
                <TableCell className={cx(classes.deleteButtonContainer)}>
                  <DeleteConfirmationButton
                    onConfirmAction={() =>
                      actions.deleteAllowedOrigin(props.paymentService, origin)
                    }
                    title={`Are you sure you want to delete ${origin}?`}
                    errorMessage="Failed to delete origin"
                    successMessage={`${origin} deleted`}
                  />
                </TableCell>
              </TableRow>
            ))}
            <TableRow key={origin} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>
                <Input
                  type={'text'}
                  value={newOrigin}
                  name={'origin'}
                  disabled={state.loading}
                  placeholder={'Enter origin. E.g. https://example.com'}
                  onChange={(p) => {
                    setNewOrigin(p.target.value)
                  }}
                />
              </TableCell>
              <TableCell className={cx(classes.deleteButtonContainer)}>
                <LoadingButton
                  variant={'contained'}
                  type={'button'}
                  loading={state.loading}
                  onClick={addAllowedOrigin}
                >
                  Add
                </LoadingButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

const useStyles = makeStyles()(() => ({
  container: {
    padding: '0',
    display: 'flex',
    rowGap: '16px',
    columnGap: '32px',
    flexDirection: 'column',
    maxWidth: '600px',
  },
  deleteButtonContainer: {
    textAlign: 'right',
  },
}))
