import { useRecoilState } from 'recoil'
import { paymentConfigurationApprovalsListState } from '@/state/paymentConfigurationApprovals'
import { useAppConfig } from '@/hooks/useAppConfig'
import { IPaymentServiceConfiguration } from '@/models'
import { IConfigurationApprovalFilters } from '@/services/types'

export const useConfigurationApprovalsList = (provider?: string) => {
  const [state, setState] = useRecoilState(paymentConfigurationApprovalsListState(provider))

  const { config } = useAppConfig()
  const PaymentServiceConfigurations = config.factories.paymentServiceConfigurations

  const setFilters = (filters?: IConfigurationApprovalFilters, resetData = true) => {
    setState({
      ...state,
      data: resetData ? [] : state.data,
      hasNextPage: resetData ? false : state.hasNextPage,
      filters,
    })
  }

  const fetchConfigurationApprovals = async (reset = false) => {
    if (!provider) return { data: [] }

    setState({
      ...state,
      data: reset ? [] : state.data,
      hasNextPage: reset ? false : state.hasNextPage,
      error: null,
      loading: true,
    })

    try {
      const result: IPaymentServiceConfiguration[] =
        await PaymentServiceConfigurations().fetchApprovals(provider, {
          ...state.filters,
          offset: reset ? 0 : state.data.length,
        })

      setState({
        ...state,
        data: reset ? result : [...state.data, ...result],
        loading: false,
        hasNextPage: result.length > 0,
      })

      return { data: result }
    } catch (e) {
      setState({ ...state, error: e, loading: false })
      return { error: e }
    }
  }

  return {
    actions: {
      setFilters,
      loadNextPage: () => fetchConfigurationApprovals(),
      refresh: () => fetchConfigurationApprovals(true),
    },
    state,
  }
}
