import { Typography } from '@mui/material'
import { makeStyles } from '@/theme'

export function WithDescription({ description, children }) {
  const { classes, cx } = useStyles()

  if (!description) return children

  return (
    <div className={cx(classes.container)}>
      <Typography variant="subtitle2" color="text.secondary" className={cx(classes.description)}>
        {description}
      </Typography>
      {children}
    </div>
  )
}

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  description: {
    fontSize: '12px',
  },
}))
