import { ReactNode, useState } from 'react'
import { ConfirmationDialog } from '@/components/ConfirmationDialog'

interface IWithConfirmationDialogProps {
  title: string
  text: string
  onConfirm: (args) => void
  children: (params: { onOpenConfirmation: (args) => void }) => ReactNode
}
export function WithConfirmationDialog(props: IWithConfirmationDialogProps) {
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [args, setArgs] = useState()

  const closeConfirmation = () => setConfirmationOpen(false)

  const onOpenConfirmation = (args) => {
    setArgs(args)
    setConfirmationOpen(true)
  }

  const onConfirm = () => {
    closeConfirmation()
    props.onConfirm(args)
  }

  return (
    <>
      <ConfirmationDialog
        title={props.title}
        text={props.text}
        open={confirmationOpen}
        loading={false}
        onClose={closeConfirmation}
        onCancel={closeConfirmation}
        onConfirm={onConfirm}
      />
      {props.children({ onOpenConfirmation })}
    </>
  )
}
