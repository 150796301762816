import { makeStyles } from '@/theme'
import { FeeCategory, feeCategoryMap, FeeType, IFee, TPaymentService } from '@/models'
import { EmptyPlaceholder } from '@/components/EmptyPlaceholder'
import { routes } from '@/helpers/routes'
import { Button, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { ErrorMessage } from '@/components/ErrorMessage'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined'
import { formatMoney } from '@/helpers/currency'
import { DeleteConfirmationButton } from '@/components/DeleteConfirmationButton'
import { usePaymentServiceFee } from '@/hooks/usePaymentServiceFees'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import ReportIcon from '@mui/icons-material/Report'
import { capitalizeFirstLetters } from '@/helpers/strings'

interface IFeeSetupSectionProps {
  paymentService: TPaymentService
}

export const FeeSetupSection = (props: IFeeSetupSectionProps) => {
  const { classes, cx } = useStyles()

  const provider = props.paymentService.provider

  const {
    state: {
      fees,
      error,
      loading,
      hasSurchargeFeesOption,
      isShopFlatSurchargeFeesEnabled,
      isShopPercentageSurchargeFeesEnabled,
      hasSurchargeFeeAdded,
      hasNonSurchargeFeeAdded,
      hasManualFeesOption,
      isShopFlatManualFeesEnabled,
      isShopPercentageManualFeesEnabled,
    },
    actions: feeActions,
  } = usePaymentServiceFee(provider)

  if (error) {
    return <ErrorMessage error={error} />
  }

  if (loading) {
    return <CircularProgress />
  }

  if (fees.length === 0) {
    return (
      <div
        style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', rowGap: '24px' }}
      >
        <EmptyPlaceholder
          title={'No fees configured'}
          description={'At the moment you have no fees configured'}
        />
        <AddFeeButton paymentService={props.paymentService} sx={{ alignSelf: 'center' }} />
      </div>
    )
  }

  const showAlertMsg = fees.some((fee) => {
    let alert = false
    if (fee.feeCategory === FeeCategory.MANUAL) {
      if (!hasManualFeesOption) alert = true
      if (
        !alert &&
        ((fee.feeType === FeeType.Flat && !isShopFlatManualFeesEnabled) ||
          (fee.feeType === FeeType.Percentage && !isShopPercentageManualFeesEnabled))
      )
        alert = true
    }
    if (fee.feeCategory === FeeCategory.SURCHARGE) {
      if (!hasSurchargeFeesOption) alert = true
      if (
        !alert &&
        ((fee.feeType === FeeType.Flat && !isShopFlatSurchargeFeesEnabled) ||
          (fee.feeType === FeeType.Percentage && !isShopPercentageSurchargeFeesEnabled))
      )
        alert = true
    }
    return alert
  })

  return (
    <div className={cx(classes.container)}>
      <div className={cx(classes.headerContainer)}>
        <FeeDisclaimer />

        <AddFeeButton
          paymentService={props.paymentService}
          sx={{ alignSelf: 'self-end' }}
          disabled={
            (hasManualFeesOption && !hasSurchargeFeesOption && hasNonSurchargeFeeAdded) ||
            (hasSurchargeFeesOption && !hasManualFeesOption && hasSurchargeFeeAdded) ||
            (hasManualFeesOption &&
              hasSurchargeFeesOption &&
              hasNonSurchargeFeeAdded &&
              hasSurchargeFeeAdded)
          }
          disabledMessage={
            hasManualFeesOption && !hasSurchargeFeesOption && hasNonSurchargeFeeAdded
              ? 'You can only have at most one fee.'
              : hasSurchargeFeesOption && !hasManualFeesOption && hasSurchargeFeeAdded
              ? 'You can only have at most one surcharge fee.'
              : 'You can only have at most one surcharge and one non-surcharge fee.'
          }
        />
      </div>

      <div style={{ display: showAlertMsg ? 'inline-block' : 'none', fontStyle: 'italic' }}>
        Some fee settings have been disabled by your administrator. Hover over warning icons for
        more details.
        <ReportIcon color={'error'} fontSize={'medium'} />
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Fee Category</TableCell>
              <TableCell>Fee Name</TableCell>
              <TableCell>Fee Amount</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {fees.map((fee) => {
              let feeTypeAlert = false
              let feeCategoryAlert = false
              if (fee.feeCategory === FeeCategory.MANUAL) {
                if (!hasManualFeesOption) feeCategoryAlert = true
                if (
                  !feeCategoryAlert &&
                  ((fee.feeType === FeeType.Flat && !isShopFlatManualFeesEnabled) ||
                    (fee.feeType === FeeType.Percentage && !isShopPercentageManualFeesEnabled))
                )
                  feeTypeAlert = true
              }
              if (fee.feeCategory === FeeCategory.SURCHARGE) {
                if (!hasSurchargeFeesOption) feeCategoryAlert = true
                if (
                  !feeCategoryAlert &&
                  ((fee.feeType === FeeType.Flat && !isShopFlatSurchargeFeesEnabled) ||
                    (fee.feeType === FeeType.Percentage && !isShopPercentageSurchargeFeesEnabled))
                )
                  feeTypeAlert = true
              }
              const formattedFee = formatFee(fee)
              return (
                <TableRow key={fee.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    {formattedFee.feeType}
                    <Tooltip
                      title="This fee type ($ or %) has been disabled by your administrator. Fees using this type are no longer active."
                      style={{
                        verticalAlign: 'sub',
                        display: feeTypeAlert ? 'inline-block' : 'none',
                      }}
                    >
                      <ReportIcon color={'error'} fontSize={'small'} />
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {capitalizeFirstLetters(
                      feeCategoryMap[formattedFee.formattedFeeCategory.toLowerCase()],
                    )}
                    <Tooltip
                      title="This fee category has been disabled by your administrator. Previously configured fees under this category are no longer active."
                      style={{
                        verticalAlign: 'sub',
                        display: feeCategoryAlert ? 'inline-block' : 'none',
                      }}
                    >
                      <ReportIcon color={'error'} fontSize={'small'} />
                    </Tooltip>
                  </TableCell>
                  <TableCell>{fee.name}</TableCell>
                  <TableCell>{formattedFee.formattedAmount}</TableCell>
                  <TableCell>
                    <EditFeeButton paymentService={props.paymentService} fee={fee} />
                    <DeleteConfirmationButton
                      onConfirmAction={() => feeActions.deleteFee(fee)}
                      title="Are you sure you want to delete this fee?"
                      text="The selected fee will be permanently deleted"
                      errorMessage="Failed to update fee"
                      successMessage="Fee deleted"
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

function EditFeeButton({ paymentService, fee }) {
  const navigate = useNavigate()
  const location = useLocation()

  const onClickEditFee = () => {
    navigate(routes.editFee(paymentService.provider), {
      state: { previousLocation: location, fee: fee },
    })
  }

  return (
    <IconButton onClick={onClickEditFee}>
      <BorderColorOutlinedIcon sx={{ fontSize: '24px' }} />
    </IconButton>
  )
}

function AddFeeButton({ paymentService, sx, disabled = false, disabledMessage = '' }) {
  const navigate = useNavigate()
  const location = useLocation()

  const onClickNewFee = () => {
    navigate(routes.newFee(paymentService.provider), {
      state: { previousLocation: location },
    })
  }

  return (
    <Tooltip title={disabled ? disabledMessage : ''} followCursor>
      <div style={sx}>
        <Button
          disabled={disabled}
          onClick={onClickNewFee}
          variant={'contained'}
          size={'large'}
          type={'button'}
        >
          Add Fee
        </Button>
      </div>
    </Tooltip>
  )
}

function FeeDisclaimer() {
  const { cx, classes } = useStyles()

  return (
    <div className={cx(classes.feeDisclaimerContainer)}>
      <div>
        <WarningRoundedIcon color={'error'} fontSize={'large'} />
      </div>
      <div>
        <Typography variant="subtitle1" color="text.primary">
          Please consult with legal or tax professionals for guidance to ensure fee configurations
          are federally and locally compliant.
        </Typography>
      </div>
    </div>
  )
}

function formatFee(fee: IFee): {
  feeType: string
  formattedAmount: string
  formattedFeeCategory: string
} {
  const { feeType, amount, feeCategory } = fee

  const formattedFeeCategory =
    Object.keys(FeeCategory).find((key) => FeeCategory[key] === feeCategory) || ''

  let formattedAmount: string
  let formattedFeeType: string

  switch (feeType) {
    case FeeType.Percentage:
      formattedAmount = `${amount.toFixed(2)}%`
      formattedFeeType = 'Percentage'
      break
    case FeeType.Flat:
      formattedAmount = formatMoney(amount)
      formattedFeeType = 'Flat'
      break
    default:
      formattedAmount = '-'
      formattedFeeType = '-'
  }

  return { feeType: formattedFeeType, formattedAmount, formattedFeeCategory }
}

const useStyles = makeStyles()(() => ({
  container: {
    padding: '0',
    display: 'flex',
    rowGap: '16px',
    columnGap: '32px',
    flexDirection: 'column',
  },
  feeDisclaimerContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    maxWidth: 1024,
  },
  headerContainer: { display: 'flex', justifyContent: 'space-between' },
}))
