import * as React from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { useState } from 'react'
import { makeStyles } from '@/theme'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

interface IFileUploadProps {
  disabled?: boolean
  initialValue?: string
  value?: FileList
  onChange: (data: FileList | null) => void
}
export function FileUpload(props: IFileUploadProps) {
  const { classes, cx } = useStyles()

  const [selectedFile, setSelectedFile] = useState(props.initialValue)

  const onChange = (event) => {
    const file = event.target.files[0]
    if (!file) return

    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        setSelectedFile(reader.result)
      }
    }

    props.onChange(event.target.files)
  }

  return (
    <div className={cx(classes.root)}>
      {selectedFile && (
        <div className={cx(classes.imageContainer)}>
          <img src={selectedFile} className={cx(classes.image)} />
        </div>
      )}
      <Button
        disabled={props.disabled}
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
        className={cx(classes.button)}
      >
        Upload file
        <VisuallyHiddenInput type="file" accept="image/*" onChange={onChange} />
      </Button>
    </div>
  )
}

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
  },
  button: {
    width: '150px',
  },
  imageContainer: {
    height: '200px',
    width: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: '4px',
  },
  image: {
    maxHeight: '190px',
    maxWidth: '190px',
  },
}))
