import { useRef } from 'react'
import { BulletList } from '@tiptap/extension-bullet-list'
import { Document } from '@tiptap/extension-document'
import { History } from '@tiptap/extension-history'
import { Link } from '@tiptap/extension-link'
import { ListItem } from '@tiptap/extension-list-item'
import { OrderedList } from '@tiptap/extension-ordered-list'
import { Paragraph } from '@tiptap/extension-paragraph'
import { Placeholder } from '@tiptap/extension-placeholder'
import { Text } from '@tiptap/extension-text'
import {
  LinkBubbleMenu,
  LinkBubbleMenuHandler,
  MenuButtonBulletedList,
  MenuButtonEditLink,
  MenuButtonOrderedList,
  MenuControlsContainer,
  MenuDivider,
  RichTextEditor,
  type RichTextEditorRef,
} from 'mui-tiptap'
import { makeStyles } from '@/theme'

const CustomLinkExtension = Link.extend({
  inclusive: false,
})

interface IRichTextInputProps {
  disabled?: boolean
  error?: boolean
  placeholder?: string
  value: string
  onChange: (value: string) => void
}

export function RichTextInput(props: IRichTextInputProps) {
  const { disabled, error, placeholder, value, onChange } = props
  const { classes, cx } = useStyles({ error: !!error })
  const rteRef = useRef<RichTextEditorRef>(null)

  const extensions = [
    // We use some but not all of the extensions from
    // https://tiptap.dev/api/extensions/starter-kit, plus a few additional ones
    BulletList,
    Document,
    ListItem,
    OrderedList,
    Paragraph,
    Text,
    CustomLinkExtension.configure({
      autolink: true,
      linkOnPaste: true,
      openOnClick: false,
    }),
    LinkBubbleMenuHandler,
    Placeholder.configure({
      placeholder,
    }),
    History,
  ]

  return (
    <RichTextEditor
      ref={rteRef}
      className={cx(classes.rte)}
      editable={!disabled}
      onUpdate={() => onChange(rteRef.current?.editor?.getHTML() ?? '')}
      content={value}
      extensions={extensions}
      renderControls={() => (
        <MenuControlsContainer>
          <MenuButtonEditLink />
          <MenuDivider />
          <MenuButtonOrderedList />
          <MenuButtonBulletedList />
        </MenuControlsContainer>
      )}
    >
      {() => <LinkBubbleMenu />}
    </RichTextEditor>
  )
}

const useStyles = makeStyles<{ error: boolean }>()((theme, params) => ({
  rte: {
    zIndex: 0,
    '> div': {
      borderColor: params.error ? 'red !important' : 'inherit',
    },
  },
}))
