import { useState } from 'react'
import { useToast } from '@/hooks/useToast'
import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { ConfirmationDialog } from '@/components/ConfirmationDialog'
import { makeStyles } from '@/theme'
import { TAnyError } from '@/models'

interface IDeleteConfirmationButton {
  onConfirmAction?: () => Promise<{ error?: TAnyError }>
  onConfirmActionWithoutPromise?: () => void
  onDeleteSuccessful?: () => void
  errorMessage: string
  successMessage: string
  title: string
  text?: string
  disabled?: boolean
}
export function DeleteConfirmationButton(props: IDeleteConfirmationButton) {
  const {
    onConfirmAction,
    onConfirmActionWithoutPromise,
    onDeleteSuccessful,
    errorMessage,
    successMessage,
    title,
    text,
    disabled,
  } = props
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const { classes, cx } = useStyles()
  const toast = useToast()
  const closeModal = () => {
    setConfirmationOpen(false)
  }

  const onConfirm = () => {
    setDeleting(true)

    onConfirmActionWithoutPromise?.()

    if (onConfirmAction) {
      onConfirmAction()
        .then((result) => {
          if (result.error) {
            toast.showMessage(errorMessage, 'error')
          } else {
            toast.showMessage(successMessage, 'success')
            onDeleteSuccessful?.()
          }
        })
        .catch(() => {
          toast.showMessage(errorMessage, 'error')
        })
        .finally(() => {
          setDeleting(false)
          closeModal()
        })
    }
  }

  return (
    <>
      <IconButton
        onClick={() => setConfirmationOpen(true)}
        className={cx(classes.deleteButton)}
        disabled={disabled}
      >
        <DeleteIcon sx={{ fontSize: '24px' }} />
      </IconButton>
      <ConfirmationDialog
        title={title}
        text={text}
        open={confirmationOpen}
        loading={deleting}
        onClose={closeModal}
        onCancel={closeModal}
        onConfirm={onConfirm}
      />
    </>
  )
}

const useStyles = makeStyles()(() => ({
  deleteButton: {
    height: '40px',
    width: '40px',
  },
}))
