import { Button, CardActions, CardContent, Typography } from '@mui/material'
import { makeStyles } from '@/theme'
import { IPaymentOption } from '@/models'
import { useNavigate } from 'react-router-dom'
import { routes } from '@/helpers/routes'

interface IPaymentServiceCardReadOnlyProps {
  paymentOption: IPaymentOption
}

export function PaymentOptionCardContent(props: IPaymentServiceCardReadOnlyProps) {
  const { paymentOption } = props
  const { classes, cx } = useStyles()

  const navigate = useNavigate()

  return (
    <>
      <CardContent className={cx(classes.content)}>
        <Typography className={cx(classes.paymentServiceName)} color="text.primary" gutterBottom>
          {paymentOption.provider}
        </Typography>
      </CardContent>

      <CardActions className={cx(classes.actions)}>
        <Button
          onClick={() => navigate(routes.adminPaymentOptionEdit(paymentOption.provider))}
          sx={{ alignSelf: 'flex-end' }}
          variant={'contained'}
          size={'small'}
          type={'button'}
        >
          Edit
        </Button>
      </CardActions>
    </>
  )
}

const useStyles = makeStyles()(() => ({
  content: { padding: '8px 0 0 0' },
  paymentServiceName: {
    fontSize: 16,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  paymentServiceProvider: { fontSize: 14, margin: 0 },
  actions: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  moreInfoLink: { fontSize: 12, margin: 0 },
}))
