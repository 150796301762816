import { CSSProperties, ReactElement, ReactNode } from 'react'
import { CardActions, Typography } from '@mui/material'
import { Box } from '@/components/Box'
import { makeStyles } from '@/theme'
import { BackButton } from '@/components/BackButton'

interface IPanelProps {
  title: ReactNode
  description: ReactNode
  actions: ReactElement | null
  content: ReactElement | null
  onBack?: () => void
  customStyles?: CSSProperties
}

export function Panel(props: IPanelProps) {
  const { classes, cx } = useStyles()

  return (
    <Box className={cx(classes.root)} style={props.customStyles}>
      {props.onBack && (
        <div className={cx(classes.backButtonContainer)}>
          <BackButton onClick={props.onBack} />
        </div>
      )}
      <CardActions
        sx={{
          padding: 0,
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: props.content ? '16px' : '0',
        }}
      >
        <div>
          <Typography variant="subtitle1" color="text.primary">
            {props.title}
          </Typography>
          {typeof props.description === 'string' ? (
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{ maxWidth: 900 }}
              dangerouslySetInnerHTML={{ __html: props.description }}
            />
          ) : (
            props.description
          )}
        </div>
        {props.actions && <div>{props.actions}</div>}
      </CardActions>

      {props.content}
    </Box>
  )
}

const useStyles = makeStyles()(() => ({
  root: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    margin: '16px',
  },
  backButtonContainer: {
    marginBottom: '16px',
  },
}))
