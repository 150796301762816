import { TAnyError, IPaymentOption } from '@/models'
import { atom } from 'recoil'

export interface PaymentOptionState {
  loading: boolean
  error?: TAnyError | null
  data: IPaymentOption | null
}

export const paymentOptionState = atom<PaymentOptionState>({
  key: 'PaymentOptionDetail',
  default: {
    loading: false,
    error: null,
    data: null,
  },
})
