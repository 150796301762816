import { useRecoilState } from 'recoil'
import { useAppConfig } from '@/hooks/useAppConfig'
import { IPaymentServiceConfiguration } from '@/models'
import { paymentServiceLocationsState } from '@/state/paymentServiceLocations'

export function usePaymentServiceLocations(provider: string) {
  const [state, setState] = useRecoilState(paymentServiceLocationsState(provider))

  const { config } = useAppConfig()
  const PaymentServiceConfigurations = config.factories.paymentServiceConfigurations

  const fetchLocations = async () => {
    setState({ ...state, loading: true, error: null })

    try {
      const data: IPaymentServiceConfiguration =
        await PaymentServiceConfigurations().fetchByProviderWithMeta(provider)
      const { availableClientIds, clientId: selectedClientId } = data
      setState((prevState) => ({
        ...prevState,
        loading: false,
        error: null,
        data: {
          ...prevState.data,
          availableClientIds: availableClientIds || [],
          selectedClientId,
        },
      }))
      return data
    } catch (e) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        error: e,
        data: {
          ...prevState.data,
          availableClientIds: [],
        },
      }))
      throw new Error(e)
    }
  }

  const updateLocation = async (locationId: string) => {
    try {
      const result = await PaymentServiceConfigurations().updateSettings({
        provider,
        data: { clientId: locationId },
      })
      return { data: result }
    } catch (e) {
      return { error: e }
    }
  }

  return { state, actions: { fetchLocations, updateLocation } }
}
