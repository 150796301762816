import { makeStyles } from '@/theme'
import { ConfigurationEntry } from '@/pages/PaymentServiceSettingsPage/ConfigurationEntry'
import { TPaymentService } from '@/models'
import get from 'lodash/get'
import { IconButton, Typography } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useToast } from '@/hooks/useToast'

interface IConfigurationSectionProps {
  paymentService: TPaymentService
}

export const ConfigurationSection = (props: IConfigurationSectionProps) => {
  const { classes, cx } = useStyles()
  const toast = useToast()

  const { paymentService } = props
  const fieldEntries = paymentService.fields
    .filter((field) => get(paymentService, field.name)?.toString() && !field.writeOnly)
    .map((field) => ({
      name: field.name,
      label: field.label,
      value: get(paymentService, field.name),
    }))

  const handleCopy = () => {
    void navigator.clipboard.writeText(`${paymentService.merchantId}`)
    toast.showMessage('BLAZE Merchant ID copied.', 'info')
  }

  return (
    <div className={cx(classes.container)}>
      {fieldEntries.map((config, index) => (
        <ConfigurationEntry key={index} {...config} paymentService={paymentService} />
      ))}
      <div>
        <Typography variant="subtitle1" color="text.secondary" sx={{ fontSize: '14px' }}>
          BLAZE Merchant ID
        </Typography>
        <div className={cx(classes.valueContainer)}>
          <Typography variant="subtitle2" color="text.primary" className={cx(classes.value)}>
            {paymentService.merchantId}
          </Typography>
          <IconButton onClick={handleCopy}>
            <ContentCopyIcon sx={{ fontSize: '20px' }} />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles()(() => ({
  container: {
    padding: '0',
    display: 'flex',
    rowGap: '16px',
    columnGap: '32px',
    flexWrap: 'wrap',
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  value: {
    whiteSpace: 'nowrap',
    maxWidth: '250px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))
