import { Alert, Portal, Snackbar } from '@mui/material'
import { useState, createContext } from 'react'

type TSeverity = 'error' | 'success' | 'info'
export const ToastContext = createContext({
  showMessage: (message: string, severity: TSeverity) => {
    console.log(`${message} - ${severity}`)
  },
})

export function ToastContextProvider({ children }) {
  const [message, setMessage] = useState<{ text: string; severity: TSeverity }>({
    text: '',
    severity: 'success',
  })
  const [displayMessage, setDisplayMessage] = useState<boolean>(false)

  const showMessage = (text, severity) => {
    setMessage({ text, severity })
    setDisplayMessage(true)
  }

  return (
    <ToastContext.Provider value={{ showMessage }}>
      <Toast open={displayMessage} onClose={() => setDisplayMessage(false)} {...message} />
      {children}
    </ToastContext.Provider>
  )
}

function Toast(props: { open: boolean; onClose: () => void; severity: TSeverity; text: string }) {
  return (
    <Portal>
      <Snackbar
        open={props.open}
        autoHideDuration={6000}
        onClose={props.onClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={props.onClose}
          severity={props.severity}
          sx={{ width: '100%', whiteSpace: 'pre-line' }}
        >
          {props.text}
        </Alert>
      </Snackbar>
    </Portal>
  )
}
