import { useRecoilState, useRecoilValue } from 'recoil'
import {
  paymentServicesListElementState,
  paymentServicesListFilters,
  paymentServicesListSelector,
  paymentServicesListState,
} from '@/state/paymentServicesList'
import { TPaymentService } from '@/models'
import { EventConstants } from '@/constants'
import { useAppConfig } from '@/hooks/useAppConfig'

export function usePaymentServiceListElement(paymentService: TPaymentService) {
  const [state, setElementState] = useRecoilState(
    paymentServicesListElementState(paymentService.provider),
  )
  const [listState, setListState] = useRecoilState(paymentServicesListState)

  const { config } = useAppConfig()
  const PaymentServiceConfigurations = config.factories.paymentServiceConfigurations

  const toggleField = async (field: string) => {
    const EventBus = config.factories.eventBus
    setElementState({ loading: true, error: null })
    try {
      const updatedPaymentService = await PaymentServiceConfigurations().update({
        provider: paymentService.provider,
        [field]: !paymentService[field],
      })
      setElementState({ loading: false, error: null })
      setListState({
        ...listState,
        data: listState.data.map((paymentService) => {
          if (paymentService.provider !== updatedPaymentService.provider) return paymentService

          return { ...paymentService, ...updatedPaymentService }
        }),
      })
      EventBus().publish(EventConstants.PAYMENT_SERVICE_UPDATED, {
        paymentService: updatedPaymentService,
      })
      return { data: updatedPaymentService }
    } catch (e) {
      setElementState({ loading: false, error: e })
      return { error: e }
    }
  }

  return {
    state,
    actions: {
      toggleField,
    },
  }
}

export function usePaymentServicesList() {
  const [listState, setListState] = useRecoilState(paymentServicesListState)
  const list = useRecoilValue(paymentServicesListSelector)

  const { config } = useAppConfig()
  const PaymentServices = config.factories.paymentServices
  const EventBus = config.factories.eventBus

  const fetchPaymentServices = async () => {
    setListState({ ...listState, error: null, loading: true })
    try {
      const res: TPaymentService[] = await PaymentServices().fetchAll()
      EventBus().publish(EventConstants.PAYMENT_SERVICES_FETCHED, {
        paymentServicesCount: res.length,
      })
      setListState({ ...listState, data: res, loading: false })
      return { data: res }
    } catch (e) {
      setListState({ ...listState, error: e, loading: false })
      return { error: e }
    }
  }

  return {
    state: {
      ...listState,
      data: list,
    },
    actions: {
      fetchPaymentServices,
    },
  }
}

export function usePaymentServicesFilters() {
  const [filtersState, setFiltersState] = useRecoilState(paymentServicesListFilters)

  const showActive = (show: boolean) => {
    setFiltersState({ ...filtersState, showActive: show })
  }

  const showInactive = (show: boolean) => {
    setFiltersState({ ...filtersState, showInactive: show })
  }

  return {
    state: filtersState,
    actions: {
      showActive,
      showInactive,
    },
  }
}
