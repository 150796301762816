import { EPaymentConfigurationStatus } from '@/models'
import { PaymentPageModels } from '@getgreenline/mfe'

export const EventConstants = PaymentPageModels.PaymentPageEventKeys

export const RecoilConstants = {
  HAS_VALUE: 'hasValue',
}

export const AppConstants = {
  PAYMENT_DISPLAY_NAME_LIMIT: 30,
  FEE_NAME_LIMIT: 20,
}

export const Endpoints = {
  v1: {
    upsertPaymentOption: (provider) => `/api/v1/providers/${provider}/payment-options`,
    paymentOptions: (merchantId) => `/api/v1/merchants/${merchantId}/payment-options`,
    adminPaymentOptions: () => `/api/v1/payment-options`,
    providerConfigurations: (merchantId: string, provider: string) =>
      `/api/v1/merchants/${merchantId}/providers/${provider}/configurations`,
    configurations: (merchantId: string) => `/api/v1/merchants/${merchantId}/configurations`,
    configurationApprovals: () => `/api/v1/configurations`,
    providers: () => `/api/v1/providers`,
    terminals: (merchantId: string, provider: string) =>
      `/api/v1/merchants/${merchantId}/providers/${provider}/terminals/`,
    terminal: (merchantId: string, provider: string, terminalId: string) =>
      `/api/v1/merchants/${merchantId}/providers/${provider}/terminals/pos/${terminalId}`,
    fees: (merchantId: string, provider: string, feeId?: string) =>
      `/api/v1/merchants/${merchantId}/providers/${provider}/configurations/fees/${feeId}`,
  },
}

export const ErrorMessagesByStatus = {
  401: {
    title: 'Unauthorized',
    description: 'You need to be authenticated to access this resource',
  },
  403: {
    title: 'Forbidden',
    description: "You don't have permission to access this resource",
  },
  unknown: {
    title: 'An error has occurred',
    description: 'An unknown error has occurred, please contact support',
  },
}

export const PaymentConfigurationStatusMessages = {
  [EPaymentConfigurationStatus.PENDING_APPROVAL]:
    'Your request has been submitted. We will review it soon.',

  [EPaymentConfigurationStatus.DENIED]: 'Your request has been denied.',
}
