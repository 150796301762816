import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '@/helpers/routes'
import { Panel } from '@/components/Panel'
import { UpsertPaymentMethodForm } from '@/components/forms/UpsertPaymentMethodForm'
import { useAdminPaymentOptionUpsert } from '@/hooks/useAdminPaymentOptionUpsert'
import { CircularProgress } from '@mui/material'
import { usePaymentOptionsRequiredFields } from '@/hooks/useAdminPaymentOptionsRequiredFields'
import { useAvailablePaymentProvidersForCreation } from '@/hooks/useAvailablePaymentProvidersForCreation'
import { useEffect } from 'react'
import { ConfigurationApprovalsDrawer } from '@/components/ConfigurationApprovalsDrawer'
import { makeStyles } from '@/theme'
import { Badge } from '@/components/Badge'

export const AdminPaymentMethodDetail = () => {
  const { classes, cx } = useStyles()
  const { provider } = useParams()
  const { requiredFields } = usePaymentOptionsRequiredFields()
  const { providers, canCreate } = useAvailablePaymentProvidersForCreation()
  const {
    state: { loading: loading, data: paymentOption },
    actions,
  } = useAdminPaymentOptionUpsert(provider)
  const navigate = useNavigate()
  const onBack = () => {
    navigate(routes.adminPaymentOptionsList())
  }

  const displayLoading =
    provider && (!paymentOption || paymentOption.provider !== provider) && loading

  useEffect(() => {
    if (!canCreate && !provider) {
      navigate(routes.adminPaymentOptionsList())
    }
  }, [canCreate, provider])

  const content = displayLoading ? (
    <CircularProgress />
  ) : (
    <UpsertPaymentMethodForm
      requiredFields={requiredFields}
      paymentOption={provider ? paymentOption : null}
      onCancel={onBack}
      onSuccess={onBack}
      onSubmit={actions.upsert}
      availablePaymentProvidersForCreation={providers || []}
    />
  )
  return (
    <>
      <Panel
        title={
          provider || paymentOption ? (
            <div className={cx(classes.titleContainer)}>
              <span>Edit Payment Option</span>
              {paymentOption?.isBlazePay && <Badge text="BLAZEPAY" />}
            </div>
          ) : (
            'Create New Payment'
          )
        }
        description={''}
        actions={null}
        content={content}
        onBack={onBack}
      />
      <ConfigurationApprovalsDrawer provider={provider} />
    </>
  )
}

const useStyles = makeStyles()(() => ({
  titleContainer: {
    display: 'flex',
    gap: '10px',
  },
}))
