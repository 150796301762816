import { useEffect } from 'react'
import { PaymentServiceCard } from '@/components/PaymentServiceCard'
import { usePaymentServicesFilters, usePaymentServicesList } from '@/hooks/usePaymentServicesList'
import { Checkbox, CircularProgress, FormControlLabel, Grid } from '@mui/material'
import { makeStyles } from '@/theme'
import { Divider } from '@/components/Divider'
import { Panel } from '@/components/Panel'
import { ErrorMessage } from '@/components/ErrorMessage'
import { EmptyPlaceholder } from '@/components/EmptyPlaceholder'
import { useAppConfig } from '@/hooks/useAppConfig'

export const PaymentPage = () => {
  const { config: appConfig } = useAppConfig()

  return (
    <Panel
      title={appConfig.isMultiMerchant ? '' : 'Payment Methods'}
      description={
        appConfig.isMultiMerchant
          ? ''
          : 'In this section you will be able to configure your payment options.'
      }
      actions={<PaymentPageActions />}
      content={<PaymentPageContent />}
    />
  )
}

const PaymentPageActions = () => {
  const { state, actions } = usePaymentServicesFilters()
  const { classes, cx } = useStyles()

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={state.showActive}
            onClick={() => actions.showActive(!state.showActive)}
          />
        }
        className={cx(classes.checkbox)}
        label="Show Active"
      />
      <FormControlLabel
        className={cx(classes.checkbox)}
        control={
          <Checkbox
            checked={state.showInactive}
            onClick={() => actions.showInactive(!state.showInactive)}
          />
        }
        label="Show Inactive"
      />
    </>
  )
}

const PaymentPageContent = () => {
  const { state, actions } = usePaymentServicesList()

  useEffect(() => {
    if (state.data?.length === 0) {
      void actions.fetchPaymentServices()
    }
  }, [])

  const displayEmptyPlaceholder = !state.loading && state.data?.length === 0 && !state.error

  return (
    <>
      <Divider sx={{ marginBottom: '24px' }} />

      {state.loading && <CircularProgress style={{ alignSelf: 'center' }} />}

      {!state.loading && state.data?.length > 0 && (
        <Grid container spacing={2} justifyContent={{ xs: 'center', sm: 'flex-start' }}>
          {state.data.map((paymentService) => (
            <Grid key={paymentService.provider} item>
              <PaymentServiceCard paymentService={paymentService} />
            </Grid>
          ))}
        </Grid>
      )}

      {displayEmptyPlaceholder && (
        <EmptyPlaceholder
          title={'Manage Payment Methods'}
          description={'No payment methods available.'}
        />
      )}

      {state.error && <ErrorMessage error={state.error} />}
    </>
  )
}

const useStyles = makeStyles()(() => ({
  root: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    margin: '16px',
  },
  checkbox: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
    },
  },
}))
