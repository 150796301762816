import 'reflect-metadata'
import './fonts'
import { AppModes } from '@/config/types'
import { AdminApp } from '@/app/admin/AdminApp'
import { CustomerApp } from '@/app/customer/CustomerApp'

export function Root(props) {
  if (props.mode === AppModes.ADMIN) {
    return <AdminApp {...props} />
  } else {
    return <CustomerApp {...props} />
  }
}
