import { useRecoilState } from 'recoil'
import { paymentOptionsList } from '@/state/paymentOptionsList'
import { useAppConfig } from '@/hooks/useAppConfig'

export function useAdminPaymentOptions() {
  const [pOptionState, setPaymentOptionState] = useRecoilState(paymentOptionsList)

  const { config } = useAppConfig()
  const AdminPaymentOptions = config.factories.adminPaymentOptions

  const fetchAll = async () => {
    setPaymentOptionState({ ...pOptionState, error: null, loading: true })

    try {
      const { paymentOptions } = await AdminPaymentOptions().fetchAll()
      setPaymentOptionState({
        data: paymentOptions,
        error: null,
        loading: false,
      })
      return { data: paymentOptions }
    } catch (e) {
      setPaymentOptionState({ ...pOptionState, error: e, loading: false })
      return { error: e }
    }
  }

  return {
    state: pOptionState,
    actions: {
      fetchAll,
    },
  }
}
