import { Select } from '@/components/Select'
import { EPaymentConfigurationStatus } from '@/models'
import { makeStyles } from '@/theme'
import { Input } from '@/components/Input'
import { useConfigurationApprovalsList } from '@/hooks/useConfigurationApprovalsList'
import { InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useState } from 'react'

export function ConfigurationFilters({ provider }) {
  const { classes, cx } = useStyles()

  const { state, actions } = useConfigurationApprovalsList(provider)

  const [statusFilter, setStatusFilter] = useState<EPaymentConfigurationStatus[]>(
    state.filters?.status || [],
  )
  const [dirty, setDirty] = useState(false)

  const handleStatusChange = (value: EPaymentConfigurationStatus[]) => {
    setStatusFilter(value)
    setDirty(true)
  }

  const handleCloseStatusSelect = () => {
    if (dirty) actions.setFilters({ ...state.filters, status: statusFilter })
    setDirty(false)
  }

  return (
    <div className={cx(classes.container)}>
      <Select
        disabled={false}
        value={statusFilter}
        placeholder={'Select status'}
        multiple={true}
        onChange={handleStatusChange}
        onClose={handleCloseStatusSelect}
        options={statusOptions}
      />
      <Input
        placeholder={'Search merchant id or name'}
        value={state.filters?.query || ''}
        onChange={(evt) => actions.setFilters({ ...state.filters, query: evt.target.value }, false)}
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </div>
  )
}

const statusOptions = [
  { label: 'Pending', value: EPaymentConfigurationStatus.PENDING_APPROVAL },
  { label: 'Approved', value: EPaymentConfigurationStatus.APPROVED },
  { label: 'Denied', value: EPaymentConfigurationStatus.DENIED },
]

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    columnGap: '32px',
  },
}))
