import * as yup from 'yup'
import { buildSchemaObject } from '@/helpers/formBuilder'
import { useFormik } from 'formik'
import {
  IAsyncRequestResult,
  EFormFieldType,
  ITerminal,
  ITerminalOption,
  IExternalTerminal,
  IExternalPos,
} from '@/models'
import { getFieldErrors, getErrorMessages } from '@/helpers/errors'
import { useToast } from '@/hooks/useToast'
import { BaseForm } from '@/components/forms/Form'
import { trimFormValues } from '@/helpers/trimFormValues'
import * as React from 'react'

interface ITerminalConfigurationFormProps {
  onCancel: () => void
  onSuccess: () => void
  onSubmit: (terminal: ITerminal) => Promise<IAsyncRequestResult<ITerminal>>
  terminalOptions: ITerminalOption[]
  availableExternalTerminals?: IExternalTerminal[]
  availableExternalPos?: IExternalPos[]
  enabledFields?: (keyof ITerminal)[]
}

export function TerminalConfigurationForm(props: ITerminalConfigurationFormProps) {
  const toast = useToast()

  const formFields = getFormFields({
    terminalOptions: props.terminalOptions,
    availableExternalTerminals: props.availableExternalTerminals || [],
    availableExternalPos: props.availableExternalPos || [],
    enabledFields: props.enabledFields || [],
  })

  const validationSchema = yup.object(buildSchemaObject(formFields))
  const formik = useFormik<ITerminal>({
    initialValues: {
      terminalId: '',
      externalPosId: '',
      externalTerminalId: '',
      externalPosKey: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      return props.onSubmit(trimFormValues(values)).then((result) => {
        if (result.error) {
          result.error.errors && formik.setErrors(getFieldErrors(result.error))

          const errorMessages = ['Failed to update terminal', ...getErrorMessages(result.error)]

          toast.showMessage(errorMessages.join('\n'), 'error')
        } else {
          toast.showMessage('Terminal updated', 'success')
          props.onSuccess()
        }
      })
    },
  })

  return <BaseForm formikInstance={formik} onCancel={props.onCancel} formFields={formFields} />
}

const getFormFields = ({
  terminalOptions,
  availableExternalTerminals,
  availableExternalPos,
  enabledFields,
}) => {
  return [
    {
      label: 'BLAZE terminal',
      placeholder: 'Select BLAZE terminal',
      type: EFormFieldType.AUTOCOMPLETE,
      name: 'terminalId',
      required: true,
      options: terminalOptions.map((opt) => ({
        value: opt.terminalId,
        label: opt.terminalName,
      })),
      schema: yup.string().required('BLAZE terminal is required'),
      renderOption,
    },
    {
      label: 'Card reader ID',
      name: 'externalTerminalId',
      required: true,
      schema: yup.string().required('Card reader ID is required'),
      ...getOptionsOrStringInput(
        availableExternalTerminals,
        (opt) => ({
          value: opt.id,
          label: opt.name,
        }),
        'card reader ID',
      ),
      renderOption,
    },
    {
      label: 'External POS ID',
      name: 'externalPosId',
      required: true,
      schema: yup.string().required('External POS ID is required'),
      ...getOptionsOrStringInput(
        availableExternalPos,
        (opt) => ({
          value: opt.id,
          label: opt.name,
        }),
        'external POS ID',
      ),
      renderOption,
    },
    {
      label: 'External POS Key',
      name: 'externalPosKey',
      required: true,
      schema: yup.string().required('External POS Key is required'),
      placeholder: `Insert external POS Key`,
      type: EFormFieldType.STRING,
      renderOption,
    },
  ].filter((field) => enabledFields.includes(field.name) || !enabledFields.length)
}

function renderOption(props: React.HTMLAttributes<HTMLLIElement>, opt) {
  return (
    <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <div>
        <b>{opt.label || '-'}</b>
      </div>
      <div>
        <small>{opt.value}</small>
      </div>
    </li>
  )
}

function getOptionsOrStringInput(options, mapFn, fieldName) {
  if (options.length > 0) {
    return {
      type: EFormFieldType.AUTOCOMPLETE,
      placeholder: `Select ${fieldName}`,
      options: options.map(mapFn),
    }
  } else {
    return {
      placeholder: `Insert ${fieldName}`,
      type: EFormFieldType.STRING,
    }
  }
}
