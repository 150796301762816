import { ThemeProvider, Typography } from '@mui/material'
import { lightTheme, makeStyles } from '@/theme'
import { Panel } from '@/components/Panel'
import { Accordion } from '@/components/Accordion'
import { SingleMerchantApp } from '@/app/customer/SingleMerchantApp'
import { MemoryRouter } from 'react-router-dom'
import { useMemo, useState } from 'react'
import { AutoComplete } from '@/components/AutoComplete'
import { TBasicType } from '@/models'

interface ILocation {
  name: string
  merchantId: string
  companyName?: string
  email?: string
}
export function MultiMerchantApp(props) {
  const { locations, ...otherProps } = props
  const [locationsFilter, setLocationsFilter] = useState<TBasicType[]>([])

  const { cx, classes } = useStyles()
  const filterOptions = useMemo(
    () =>
      locations.map((location) => ({
        label: location.name,
        value: location.merchantId,
      })),
    [locations],
  )

  const filteredLocations = useMemo(
    () => filterLocations(locationsFilter, locations),
    [locationsFilter],
  )

  return (
    <ThemeProvider theme={lightTheme}>
      <>
        <Panel
          title={'Payment Methods'}
          description={
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '8px' }}>
              <Typography variant="subtitle2" color="text.secondary" sx={{ maxWidth: 900 }}>
                In this section you will be able to configure your payment options.
              </Typography>

              <div className={cx(classes.locationsFilterContainer)}>
                <AutoComplete
                  multiple
                  filterSelectedOptions
                  disableCloseOnSelect
                  options={filterOptions}
                  placeholder={'Select locations'}
                  onChange={(value: TBasicType[]) => setLocationsFilter(value)}
                />
              </div>
            </div>
          }
          actions={null}
          content={null}
          customStyles={{ marginBottom: '16px !important' }}
        />

        <div>
          {filteredLocations.map((location) => (
            <Accordion
              key={location.merchantId}
              title={location.name}
              content={
                <SingleMerchantApp
                  {...otherProps}
                  merchant={{
                    id: location.merchantId,
                    name: location.name,
                    companyName: location.companyName,
                    email: location.email,
                  }}
                  basename={''}
                  isMultiMerchant={true}
                  RouterComponent={MemoryRouter}
                />
              }
            />
          ))}
        </div>
      </>
    </ThemeProvider>
  )
}

const filterLocations = (locationsFilter: TBasicType[], locations: ILocation[]): ILocation[] => {
  return !locationsFilter.length
    ? locations
    : locations.filter((location) => locationsFilter.includes(location.merchantId))
}

const useStyles = makeStyles()(() => ({
  locationsFilterContainer: {
    width: 400,
  },
}))
