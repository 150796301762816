import { BrowserRouter } from 'react-router-dom'
import { AppProvider } from '@/app/common/AppProvider'
import { CustomerAppRoutes } from '@/app/customer/CustomerAppRoutes'
import { BaseApp } from '@/app/common/BaseApp'

export function SingleMerchantApp({ RouterComponent = BrowserRouter, ...props }) {
  return (
    <AppProvider RouterComponent={RouterComponent} basename={props.basename}>
      <BaseApp {...props}>
        <CustomerAppRoutes />
      </BaseApp>
    </AppProvider>
  )
}
