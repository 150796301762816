import {
  Autocomplete as AutoCompleteMui,
  createFilterOptions,
  styled,
  TextField,
} from '@mui/material'
import { ReactNode } from 'react'
import { IFormOptionEntry, TBasicType } from '@/models'
import { makeStyles } from '@/theme'
import * as React from 'react'

const StyledAutoComplete = styled(AutoCompleteMui)<{ error?: boolean; multiple?: boolean }>(
  ({ theme, error, multiple }) => ({
    width: '100% !important',
    '& .MuiInputBase-root': {
      border: `1px solid ${error ? theme.palette.error.main : theme.palette.border['200']}`,
      height: multiple ? 'auto' : '38px',
      minHeight: multiple ? '48px' : '38px',
      width: '100%',
      borderRadius: theme.decoration.border.radius.s,
      boxShadow: theme.decoration.shadow.default,
      padding: multiple ? '8px' : '0 8px',
      fontSize: '14px',
    },
    '& .MuiInputBase-input': {
      padding: '0 !important',
    },
  }),
)

interface AutoCompleteProps {
  options: IFormOptionEntry[]
  placeholder?: string
  error?: boolean
  onChange: (value: TBasicType | TBasicType[] | null) => void
  disabled?: boolean
  value?: string | number
  multiple?: boolean
  disableCloseOnSelect?: boolean
  filterSelectedOptions?: boolean
  renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, opt: IFormOptionEntry) => ReactNode
}
export function AutoComplete(props: AutoCompleteProps) {
  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: IFormOptionEntry) => {
      return option.label ? `${option.label}:${option.value}` : `${option.value}`
    },
  })

  return (
    <StyledAutoComplete
      filterSelectedOptions={props.filterSelectedOptions}
      disableCloseOnSelect={props.disableCloseOnSelect}
      multiple={props.multiple}
      disablePortal
      error={props.error}
      disabled={props.disabled}
      filterOptions={filterOptions}
      options={props.options}
      sx={{ width: 300 }}
      onChange={(evt, selected: IFormOptionEntry | IFormOptionEntry[] | null) => {
        if (Array.isArray(selected)) {
          props.onChange(selected.map((entry) => entry.value))
        } else {
          props.onChange(selected?.value || null)
        }
      }}
      getOptionLabel={(opt) => {
        const option = opt as IFormOptionEntry
        return option.label || `${option.value}`
      }}
      renderInput={(params) => <TextField {...params} placeholder={props.placeholder} />}
      renderOption={(renderProps: React.HTMLAttributes<HTMLLIElement>, option: IFormOptionEntry) =>
        props.renderOption ? (
          props.renderOption(renderProps, option)
        ) : (
          <DefaultOption label={option.label} {...renderProps} />
        )
      }
    />
  )
}

export function DefaultOption({ label, ...props }) {
  const { classes, cx } = useStyles()
  return (
    <li className={cx(classes.optionContainer)} {...props}>
      {label}
    </li>
  )
}

const useStyles = makeStyles()(() => ({
  optionContainer: {
    display: 'flex',
    padding: '8px',
  },
}))
