import { InputProps, OutlinedInput as MuiInput, styled } from '@mui/material'

const StyledInput = styled(MuiInput)<InputProps>(({ theme }) => ({
  border: `1px solid ${theme.palette.border['200']}`,
  height: '38px',
  width: '100%',
  borderRadius: theme.decoration.border.radius.s,
  boxShadow: theme.decoration.shadow.default,
  '& .MuiInputBase-input': {
    padding: '8px',
  },
  fontSize: '14px',
}))

export function Input(props: InputProps) {
  return <StyledInput {...props} />
}
