import { TextareaAutosizeProps, TextareaAutosize as MuiInput, styled } from '@mui/material'

interface ITextAreaProps extends TextareaAutosizeProps {
  error?: boolean
}

const StyledTextArea = styled(MuiInput)<ITextAreaProps>(({ theme, error }) => ({
  border: `1px solid ${error ? theme.palette.error.main : theme.palette.border['200']}`,
  height: '38px',
  width: '100%',
  borderRadius: theme.decoration.border.radius.s,
  boxShadow: theme.decoration.shadow.default,
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  padding: '8px',
  fontSize: '14px',
}))

export function TextArea(props: ITextAreaProps) {
  return <StyledTextArea {...props} />
}
