import * as yup from 'yup'
import { buildSchemaObject } from '@/helpers/formBuilder'
import { useFormik } from 'formik'
import { IAsyncRequestResult, EFormFieldType, IFee, FeeType } from '@/models'
import { getFieldErrors, getErrorMessages } from '@/helpers/errors'
import { useToast } from '@/hooks/useToast'
import { BaseForm } from '@/components/forms/Form'
import { trimFormValues } from '@/helpers/trimFormValues'
import { isNumber } from '@/helpers/isNumber'
import { useEffect } from 'react'
import { HttpStatusCode } from 'axios'
import { AppConstants } from '@/constants'

interface IFeeConfigurationFormProps {
  onCancel: () => void
  onSuccess: () => void
  onSubmit: (fee: IFee) => Promise<IAsyncRequestResult<IFee>>
  fee: IFee
}

export function FeeConfigurationForm(props: IFeeConfigurationFormProps) {
  const toast = useToast()
  const { fee } = props
  const formFields = getFormFields()

  const validationSchema = yup.object(buildSchemaObject(formFields))

  const formik = useFormik<IFee>({
    initialValues: {
      id: fee?.id,
      name: fee?.name,
      feeType: fee?.feeType,
      amount: fee?.amount,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      return props
        .onSubmit(trimFormValues(values))
        .then((result) => {
          if (result.error) {
            result.error.errors && formik.setErrors(getFieldErrors(result.error))

            const errorMessages = [
              'Failed to update fee',
              ...getErrorMessages(result.error, ({ status, detail }) =>
                status === HttpStatusCode.Conflict ? 'Fee name already exists' : detail,
              ),
            ]

            toast.showMessage(errorMessages.join('\n'), 'error')
          } else {
            toast.showMessage('Fee updated', 'success')
            props.onSuccess()
          }
        })
        .catch((e) => {
          if (!e?.message) toast.showMessage('Failed to update fee', 'error')

          toast.showMessage(e.message, 'error')
        })
    },
  })

  useEffect(() => {
    if (!fee?.amount) formik.setFieldValue('amount', 0)
  }, [formik.values.feeType])

  return <BaseForm formikInstance={formik} onCancel={props.onCancel} formFields={formFields} />
}

const getFormFields = () => {
  return [
    {
      label: 'Fee Name',
      placeholder: 'Insert fee name',
      type: EFormFieldType.STRING,
      name: 'name',
      required: true,
      schema: yup
        .string()
        .max(
          AppConstants.FEE_NAME_LIMIT,
          `Fee name must have ${AppConstants.FEE_NAME_LIMIT} characters or less.`,
        )
        .required('Fee name is required'),
    },
    {
      label: 'Fee Type',
      placeholder: 'Select fee type',
      type: EFormFieldType.OPTIONS,
      name: 'feeType',
      multiple: false,
      required: true,
      schema: yup.string().required('Fee type is required'),
      options: Object.keys(FeeType).map((key) => ({ label: key, value: FeeType[key] })),
    },
    {
      label: 'Fee Amount',
      placeholder: 'Insert fee amount',
      disabled: ({ formik }) => !formik.values.feeType,
      tooltip: ({ formik }) => (!formik.values.feeType ? 'Select fee type' : ''),
      type: ({ formik }) =>
        formik?.values.feeType === FeeType.Percentage
          ? EFormFieldType.PERCENT
          : EFormFieldType.CURRENCY,
      name: 'amount',
      required: true,
      schema: yup
        .number()
        .default(null)
        .test({
          message: 'Must be an integer',
          test: function (value) {
            const feeType = this.parent.feeType
            return feeType === FeeType.Percentage || (isNumber(value) && Number.isInteger(value))
          },
        })
        .test({
          message: 'Must be between 0 and 100',
          test: function (value) {
            const feeType = this.parent.feeType
            return feeType === FeeType.Flat || (isNumber(value) && value >= 0 && value <= 100)
          },
        })
        .required('Fee amount is required'),
      dimensions: { xs: 12, md: 3, lg: 6 },
    },
  ]
}
