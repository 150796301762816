import { Route, Routes, useLocation } from 'react-router-dom'
import * as TestModels from '@/models/TestModels'
import { AdminPaymentOptions } from '@/pages/AdminPaymentOptions'
import { routes } from '@/helpers/routes'
import { AdminPaymentMethodDetail } from '@/pages/AdminPaymentMethodDetail'
import { ErrorPage } from '@/pages/ErrorPage'

export function AdminAppRoutes() {
  const location = useLocation()
  const previousLocation = location.state?.previousLocation

  return (
    <div data-testid={TestModels.ETestIdEnums.PAYMENT_PAGE_ROOT} style={{ position: 'relative' }}>
      <Routes location={previousLocation || location}>
        <Route index element={<AdminPaymentOptions />} />

        <Route path={routes.adminPaymentOptionCreation()} element={<AdminPaymentMethodDetail />} />
        <Route
          path={routes.adminPaymentOptionEdit(':provider')}
          element={<AdminPaymentMethodDetail />}
        />

        <Route path={routes.error()} element={<ErrorPage />} />

        <Route path="*" element={<AdminPaymentOptions />} />
      </Routes>
    </div>
  )
}
