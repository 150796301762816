import { Grid, IconButton } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import { makeStyles } from '@/theme'

interface INumberPickerProps {
  disabled?: boolean
  value: string | number
  onChange: (value: number) => void
  min?: number
  max?: number
}
export function NumberPicker(props: INumberPickerProps) {
  const { classes, cx } = useStyles()

  const value = getNumberValue(props.value)

  const onDecrement = () => {
    let nextValue = value - 1
    if (props.min) {
      nextValue = Math.max(props.min, nextValue)
    }
    props.onChange(nextValue)
  }

  const onIncrement = () => {
    let nextValue = value + 1
    if (props.max) {
      nextValue = Math.min(props.max, nextValue)
    }
    props.onChange(nextValue)
  }

  return (
    <Grid container className={cx(classes.root)}>
      <IconButton disabled={props.disabled} className={cx(classes.button)} onClick={onDecrement}>
        <RemoveIcon sx={{ fontSize: '20px' }} />
      </IconButton>
      <span className={cx(classes.value)}>{value}</span>
      <IconButton disabled={props.disabled} className={cx(classes.button)} onClick={onIncrement}>
        <AddIcon sx={{ fontSize: '20px' }} />
      </IconButton>
    </Grid>
  )
}

function getNumberValue(value?: string | number | null): number {
  if (typeof value === 'number') return value
  if (typeof value === 'string' && !isNaN(parseInt(value))) return parseInt(value)

  return 0
}

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: '8px',
  },
  button: {
    border: `1px solid ${theme.palette.text.primary}`,
    padding: '2px',
    color: theme.palette.text.primary,
  },
  value: {
    minWidth: '24px',
    textAlign: 'center',
  },
}))
