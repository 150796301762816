import { selector, useRecoilValueLoadable } from 'recoil'
import { RecoilConstants } from '@/constants'
import { appConfigState } from '@/state/appConfig'

const paymentOptionsRequiredFields = selector({
  key: 'paymentOptionsRequiredFields',
  get: async ({ get }) => {
    const config = get(appConfigState)
    const AdminPaymentOptions = config.factories.adminPaymentOptions
    const { meta } = await AdminPaymentOptions().fetchAll()
    return meta.requiredFields
  },
})

export const usePaymentOptionsRequiredFields = () => {
  const { state, contents } = useRecoilValueLoadable(paymentOptionsRequiredFields)

  return {
    requiredFields: state === RecoilConstants.HAS_VALUE ? contents : [],
  }
}
