import { makeStyles } from '@/theme'

interface IBadgeProps {
  text: string
}

export function Badge(props: IBadgeProps) {
  const { text } = props
  const { classes, cx } = useStyles()

  return <div className={cx(classes.badge)}>{text}</div>
}

const useStyles = makeStyles()(() => ({
  badge: {
    backgroundColor: '#6c757d',
    color: '#fff',
    fontWeight: '700',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '2px 4px',
  },
}))
