import { makeStyles } from '@/theme'
import { IconButton, Switch } from '@mui/material'
import { TPaymentService } from '@/models'
import { PaymentServiceLogo } from '@/components/PaymentServiceLogo'
import SettingsIcon from '@mui/icons-material/Settings'
import { Divider } from '@/components/Divider'
import { useNavigate } from 'react-router-dom'
import { routes } from '@/helpers/routes'
import { PaymentServiceApprovalStatusBadge } from '@/components/PaymentServiceApprovalStatusBadge'

interface IPaymentServiceCardHeaderProps {
  paymentService: TPaymentService
  loading: boolean
  onToggleEnabled: (enabled: boolean) => void
}

export function PaymentServiceCardHeader(props: IPaymentServiceCardHeaderProps) {
  const { classes, cx } = useStyles()
  const { paymentService } = props

  const navigate = useNavigate()

  const handleEdit = () => {
    navigate(routes.paymentServiceDetail(paymentService.provider))
  }

  return (
    <div>
      <div className={cx(classes.root)}>
        <PaymentServiceLogo
          logoUrl={paymentService.imageUrl}
          provider={paymentService.provider}
          className={cx(classes.logo)}
        />
        <div className={cx(classes.rightContainer)}>
          <PaymentServiceApprovalStatusBadge status={paymentService.status} />
          {paymentService.connected && (
            <Switch
              disabled={props.loading}
              onChange={(evt, enabled) => props.onToggleEnabled(enabled)}
              className={cx(classes.toggle)}
              checked={paymentService.enabled}
            />
          )}
          {paymentService.connected && (
            <IconButton onClick={handleEdit}>
              <SettingsIcon sx={{ fontSize: '24px' }} />
            </IconButton>
          )}
        </div>
      </div>
      <Divider />
    </div>
  )
}

const useStyles = makeStyles()((theme) => ({
  root: {
    height: 42,
    display: 'flex',
    justifyContent: 'space-between',
    padding: `0 0 ${theme.spacing(2)} 0`,
  },
  logo: {
    maxHeight: 40,
    maxWidth: 120,
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  toggle: {},
}))
