import { logos } from '@/components/PaymentServiceLogo/logos'

interface IPaymentServiceLogoProps {
  logoUrl?: null | string
  provider: string
  className?: string
}
export function PaymentServiceLogo(props: IPaymentServiceLogoProps) {
  if (!props.logoUrl && !logos[props.provider]) return <div>{props.provider}</div>
  return (
    <img
      style={{ objectFit: 'contain' }}
      src={props.logoUrl || logos[props.provider]}
      alt={props.provider}
      className={props.className}
    />
  )
}
