import { Modal as MuiModal, ModalProps, ScopedCssBaseline } from '@mui/material'

export function Modal(props: ModalProps) {
  const { children, ...otherProps } = props
  return (
    <MuiModal {...otherProps}>
      <ScopedCssBaseline>{children}</ScopedCssBaseline>
    </MuiModal>
  )
}
