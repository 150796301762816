import { atom, atomFamily, selector } from 'recoil'
import { TAnyError, TPaymentService } from '@/models'

export interface IPaymentServicesState {
  loading: boolean
  error?: TAnyError | null
  data: TPaymentService[]
}

export interface IPaymentServicesFilters {
  showActive: boolean
  showInactive: boolean
}

export interface IPaymentServicesListElementState {
  loading: boolean
  error?: TAnyError | null
}

export const paymentServicesListElementState = atomFamily<IPaymentServicesListElementState, string>(
  {
    key: 'PaymentServicesListElement',
    default: {
      loading: false,
      error: null,
    },
  },
)

export const paymentServicesListState = atom<IPaymentServicesState>({
  key: 'PaymentServicesList',
  default: {
    loading: false,
    error: null,
    data: [],
  },
})

export const paymentServicesListFilters = atom<IPaymentServicesFilters>({
  key: 'PaymentServicesFilters',
  default: {
    showActive: true,
    showInactive: true,
  },
})

export const paymentServicesListSelector = selector({
  key: 'FilteredTodoList',
  get: ({ get }) => {
    const filters = get(paymentServicesListFilters)
    const list = get(paymentServicesListState)

    return list.data.filter((pService) => {
      return (pService.enabled && filters.showActive) || (!pService.enabled && filters.showInactive)
    })
  },
})
