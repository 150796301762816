import { useRecoilState } from 'recoil'
import { paymentServicesTerminalsState } from '@/state/paymentServiceTerminals'
import { ITerminal } from '@/models'
import { useAppConfig } from '@/hooks/useAppConfig'

export function usePaymentServiceTerminals(provider: string) {
  const [state, setState] = useRecoilState(paymentServicesTerminalsState(provider))

  const { config } = useAppConfig()
  const PaymentServiceTerminals = config.factories.paymentServiceTerminals

  const fetchTerminals = async () => {
    setState({ ...state, loading: true, error: null })

    try {
      const { data, meta } = await PaymentServiceTerminals().fetchByProvider(provider)
      setState({ loading: false, error: null, data: data, meta: meta })

      return { data: data }
    } catch (e) {
      setState({ loading: false, error: e, data: [] })
      return { error: e }
    }
  }

  return {
    state,
    actions: {
      fetchTerminals,
    },
  }
}

export function usePaymentServiceTerminal(provider?: string) {
  const { config } = useAppConfig()
  const PaymentServiceTerminals = config.factories.paymentServiceTerminals

  const upsertTerminal = async (terminal: ITerminal) => {
    if (!provider) throw new Error('Missing provider')

    try {
      const result = await PaymentServiceTerminals().upsertTerminal(provider, terminal)

      return { data: result }
    } catch (e) {
      return { error: e }
    }
  }

  const deleteTerminal = async (terminal: ITerminal) => {
    if (!provider) throw new Error('Missing provider')
    if (!terminal.id) throw new Error("Can't delete terminal without id")

    try {
      const result = await PaymentServiceTerminals().deleteTerminal(provider, terminal.terminalId)

      return { data: result }
    } catch (e) {
      return { error: e }
    }
  }

  return {
    actions: {
      upsertTerminal,
      deleteTerminal,
    },
  }
}
