import { IFee } from '@/models'
import { useAppConfig } from './useAppConfig'
import { useRecoilState } from 'recoil'
import { paymentServiceState } from '@/state/paymentServiceDetail'

export function usePaymentServiceFee(provider: string) {
  const { config } = useAppConfig()
  const PaymentServiceFees = config.factories.paymentServiceFees

  const [pServiceState, setPaymentServiceState] = useRecoilState(paymentServiceState)
  const fees = pServiceState.data?.fees || []

  const insertFee = async (fee: IFee) => {
    if (!provider) throw new Error('Missing provider')
    if (fees.length > 0)
      throw new Error('You can only have at most one fee. Please remove or edit your current fee.')

    try {
      const result = await PaymentServiceFees().insertFee(provider, fee)

      return { data: result }
    } catch (e) {
      return { error: e }
    }
  }

  const updateFee = async (fee: IFee) => {
    if (!provider) throw new Error('Missing provider')
    if (!fee.id) throw new Error("Can't update fee without id")

    try {
      const result = await PaymentServiceFees().updateFee(provider, fee)

      return { data: result }
    } catch (e) {
      return { error: e }
    }
  }

  const deleteFee = async (fee: IFee) => {
    if (!provider) throw new Error('Missing provider')
    if (!fee.id) throw new Error("Can't delete fee without id")

    try {
      const result = await PaymentServiceFees().deleteFee(provider, fee.id)

      const newPaymentService = pServiceState.data ? { ...pServiceState.data } : null
      if (newPaymentService) {
        newPaymentService.fees = newPaymentService.fees?.filter(
          (paymentFee) => paymentFee.id !== fee.id,
        )
      }

      setPaymentServiceState({
        ...pServiceState,
        data: newPaymentService,
      })

      return { data: result }
    } catch (e) {
      return { error: e }
    }
  }

  return {
    state: {
      fees: fees,
      error: pServiceState.error,
      loading: pServiceState.loading,
    },
    actions: {
      insertFee,
      updateFee,
      deleteFee,
    },
  }
}
