import { makeStyles } from '@/theme'
import {
  IExternalPos,
  IExternalTerminal,
  ITerminal,
  ITerminalOption,
  TPaymentService,
} from '@/models'
import {
  usePaymentServiceTerminal,
  usePaymentServiceTerminals,
} from '@/hooks/usePaymentServiceTerminals'
import { EmptyPlaceholder } from '@/components/EmptyPlaceholder'
import { routes } from '@/helpers/routes'
import { Button, CircularProgress, Tooltip } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { ErrorMessage } from '@/components/ErrorMessage'
import { Fragment, useEffect } from 'react'
import { useTerminalOptions } from '@/hooks/useTerminalOptions'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useAvailableTerminalOptionsForCreation } from '@/hooks/useAvailableTerminalOptionsForCreation'
import { DeleteConfirmationButton } from '@/components/DeleteConfirmationButton'

interface ITerminalSetupSectionProps {
  paymentService: TPaymentService
}

export const TerminalSetupSection = (props: ITerminalSetupSectionProps) => {
  const { classes, cx } = useStyles()

  const { paymentService } = props

  const {
    state: { data: terminals, loading, error },
    actions: { fetchTerminals },
  } = usePaymentServiceTerminals(paymentService.provider)

  const { terminalOptions } = useTerminalOptions(paymentService.provider)
  const {
    state: { meta },
  } = usePaymentServiceTerminals(paymentService.provider)

  const { actions: terminalActions } = usePaymentServiceTerminal(paymentService.provider)

  useEffect(() => {
    void fetchTerminals()
  }, [paymentService.provider])

  if (error) {
    return <ErrorMessage error={error} />
  }

  if (loading) {
    return <CircularProgress />
  }

  if (terminals.length === 0) {
    return (
      <div
        style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', rowGap: '24px' }}
      >
        <EmptyPlaceholder
          title={'No terminals configured'}
          description={'At the moment you have no terminals configured'}
        />
        <AddTerminalButton provider={paymentService.provider} sx={{ alignSelf: 'center' }} />
      </div>
    )
  }

  return (
    <div className={cx(classes.container)}>
      <AddTerminalButton provider={paymentService.provider} sx={{ alignSelf: 'self-end' }} />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {renderTableHead(meta, paymentService.terminalsSetup?.fields)}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {terminals.map((terminal) => (
              <TableRow
                key={terminal.terminalId}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {renderTableValues(
                  terminal,
                  terminalOptions,
                  paymentService.terminalsSetup?.fields,
                  meta,
                )}

                <TableCell>
                  <DeleteConfirmationButton
                    onConfirmAction={() => terminalActions.deleteTerminal(terminal)}
                    onDeleteSuccessful={() => fetchTerminals()}
                    title="Are you sure you want to delete this terminal?"
                    text="The selected terminal will be permanently deleted"
                    errorMessage="Failed to delete terminal"
                    successMessage="Terminal deleted"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

function renderTableHead(meta, enabledFields?: (keyof ITerminal)[]) {
  if (!enabledFields) return null

  const displayExternalTerminalName = hasExternalTerminalNames(meta?.availableExternalTerminals)
  const displayExternalPosName = hasExternalTerminalNames(meta?.availableExternalPos)

  const entries = enabledFields
    .map((field: keyof ITerminal) => {
      switch (field) {
        case 'terminalId':
          return {
            key: field,
            label: 'Terminal Name',
            description: 'BLAZE terminal ID',
          }
        case 'externalTerminalId':
          return {
            key: field,
            label: displayExternalTerminalName ? 'Card reader Name' : '',
            description: 'Card reader ID',
          }
        case 'externalPosId':
          return {
            key: field,
            label: displayExternalPosName ? 'External POS Name' : '',
            description: 'External POS ID',
          }
        case 'externalPosKey':
          return {
            key: field,
            label: 'External POS Key',
            description: '',
          }
        default:
          return null
      }
    })
    .filter(Boolean) as { key: string; label: string; description: string }[]

  return entries.map((entry) => (
    <Fragment key={entry.key}>
      {entry.label && <TableCell>{entry.label}</TableCell>}
      {entry.description && <TableCell>{entry.description}</TableCell>}
    </Fragment>
  ))
}

function renderTableValues(
  terminal: ITerminal,
  terminalOptions: ITerminalOption[],
  enabledFields,
  meta,
) {
  const displayExternalTerminalName = hasExternalTerminalNames(meta?.availableExternalTerminals)
  const displayExternalPosName = hasExternalTerminalNames(meta?.availableExternalPos)

  const fieldEntries = enabledFields
    .map((field: keyof ITerminal) => {
      switch (field) {
        case 'terminalId':
          return {
            key: field,
            label: getTerminalName(terminal.terminalId, terminalOptions),
            value: terminal.terminalId,
          }
        case 'externalTerminalId':
          return {
            key: field,
            label: displayExternalTerminalName
              ? getExternalEntityName(terminal.externalTerminalId, meta?.availableExternalTerminals)
              : '',
            value: terminal.externalTerminalId,
          }
        case 'externalPosId':
          return {
            key: field,
            label: displayExternalPosName
              ? getExternalEntityName(terminal.externalPosId || '', meta?.availableExternalPos)
              : '',
            value: terminal.externalPosId,
          }
        case 'externalPosKey':
          return {
            key: field,
            label: '',
            value: terminal.externalPosKey,
          }
        default:
          return null
      }
    })
    .filter(Boolean)

  return fieldEntries.map((entry) => (
    <Fragment key={entry.key}>
      {entry.label && <TableCell>{entry.label}</TableCell>}
      <TableCell>{entry.value}</TableCell>
    </Fragment>
  ))
}

function AddTerminalButton({ provider, sx }) {
  const navigate = useNavigate()
  const location = useLocation()
  const { canCreate } = useAvailableTerminalOptionsForCreation(provider)

  const toolTipText = !canCreate ? 'There are no terminal options available.' : ''

  const onClickNewTerminal = () => {
    navigate(routes.newTerminal(provider), {
      state: { previousLocation: location },
    })
  }

  return (
    <Tooltip title={toolTipText} followCursor>
      <div style={sx}>
        <Button
          onClick={onClickNewTerminal}
          variant={'contained'}
          size={'large'}
          type={'button'}
          disabled={!canCreate}
        >
          Add Terminal
        </Button>
      </div>
    </Tooltip>
  )
}

function getTerminalName(terminalId: string, terminalOptions: ITerminalOption[]): string {
  const terminalOption = terminalOptions.find((option) => option.terminalId === terminalId)

  return terminalOption?.terminalName || '-'
}

function getExternalEntityName(
  externalId: string,
  external?: IExternalTerminal[] | IExternalPos[],
): string {
  const externalEntity = external?.find((extTerminal) => extTerminal.id === externalId)

  return externalEntity?.name || '-'
}

function hasExternalTerminalNames(external?: IExternalTerminal[] | IExternalPos[]) {
  return external?.some((terminal) => terminal.name)
}

const useStyles = makeStyles()(() => ({
  container: {
    padding: '0',
    display: 'flex',
    rowGap: '16px',
    columnGap: '32px',
    flexDirection: 'column',
  },
}))
